import { Component, OnInit, Inject } from '@angular/core';
import { FleaMarketBookingResponseModel, FleaMarketBookingModel } from '../../../models/flea-market.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '../../../../../node_modules/@angular/material';
import * as moment from 'moment';
import { FleaMarketService } from '../../../services/flea-market.service';
import { FleaInvoicePrintComponent } from '../../flea-invoice-print/flea-invoice-print.component';
import { STATUS_NOT_PAID, STATUS_RESERVED, STATUS_FREE } from '../../../constants/statuses';
import { HelperService } from '../../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/services/loading.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-booking-type-dialog',
  templateUrl: './change-booking-type-dialog.component.html',
  styleUrls: ['./change-booking-type-dialog.component.scss']
})
export class ChangeBookingTypeDialogComponent implements OnInit
{
  currentSpots: string[];
  currentDates: string[];

  status_not_paid = STATUS_NOT_PAID;
  status_reserved = STATUS_RESERVED;
  status_free = STATUS_FREE;

  note: string = '';

  topayControl: FormControl;

  constructor(
    public dialogRef: MatDialogRef<ChangeBookingTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FleaMarketBookingResponseModel,
    private fleaMarket: FleaMarketService,
    private dialog: MatDialog,
    private helper: HelperService,
    private i18n: TranslateService,
    private ls: LoadingService
  )
  { }

  ngOnInit()
  {
    console.log('Booking data: ', this.data);
    this.currentSpots = this.data.spots.map(s => s.name);
    this.currentDates = this.data.booking_dates.map(date => moment(date.date).format('DD.MM.YYYY.'));
    this.note = this.data.note;

    this.topayControl = new FormControl((this.data.total - this.data.payed_amount).toFixed(2), Validators.compose([
      Validators.required,
      Validators.min(0.01)
    ]));
  }

  createInvoice()
  {
    if (this.topayControl.invalid) { return; }

    this.ls.loading = true;
    this.fleaMarket.createFleaMarketInvoice(this.data.id, this.topayControl.value).subscribe(res =>
    {
      console.log('Invoice response: ', res);
      this.ls.loading = false;

      this.fleaMarket.getFleaInvoice(res.id).subscribe(invoice_res =>
      {
        const dialogRef = this.dialog.open(FleaInvoicePrintComponent, { data: invoice_res, disableClose: true, autoFocus: false });
        dialogRef.afterClosed().subscribe(dialogResponse =>
        {
          this.dialogRef.close({ ok: true });
        });
      });
    }, err =>
      {
        console.log('Invoice error', err);
        this.ls.loading = false;
      });
  }

  printPlaceNumber()
  {
    const mywindow = window.open('', '_blank');

    mywindow.document.write('<html><head><title>' + document.title + '</title><style>*{font-size:22px;font-family:Arial}</style>');
    mywindow.document.write('</head><body>');
    mywindow.document.write('<p>Platznummer(n):</p>');

    mywindow.document.write(this.data.spots.map(x => x.name).toString());
    mywindow.document.write('<br>');
    mywindow.document.write('<p>Datum:</p>');
    this.data.booking_dates.forEach(date =>
    {
      mywindow.document.write(`${moment(date.date).format('DD.MM.YYYY')}<br>`);
    });

    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
  }

  /**
   * Changes the current booking's status
   * @param status
   */
  changeBookingStatus(status: number)
  {
    if (status === -1)
    {
      this.fleaMarket.deleteBooking(this.data.id).subscribe(res =>
      {
        console.log('Delete booking response', res);
        this.dialogRef.close({ ok: true });

      }, err => console.log('Delete booking error', err));
    }
    else
    {
      this.data.status = status;
      this.fleaMarket.updateFleaMarketBooking(this.data).subscribe(res =>
      {
        console.log('Booking update response: ', res);
        this.dialogRef.close({ ok: true });
      }, err =>
      {
        console.log('Booking update error:', err);
      });
    }

  }

  saveNote()
  {
    const booking: FleaMarketBookingModel = this.data;
    booking.note = this.note;

    this.fleaMarket.updateFleaMarketBooking(booking).subscribe(res =>
    {
      console.log('Booking res', res);
      this.helper.successNoti(this.i18n.instant('NOTI.note_saved'));

    }, err => console.log('Booking error', err));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiDefinition } from '../constants/api.def';
import { Observable } from 'rxjs';
import { Price } from '../constants/price';

@Injectable({
  providedIn: 'root'
})
export class PriceService
{
  api: ApiDefinition = new ApiDefinition();

  constructor(
    private http: HttpClient
  )
  {}

  /**
   * Returns all the prices for each type of ticket
   */
  getPrices(): Observable<PriceModel[]>
  {
    return this.http.get<PriceModel[]>(this.api.prices);
  }

  /**
   * Changes the price values
   * @param prices
   */
  changePrices(prices: PriceModel[])
  {
    return this.http.put(this.api.prices, prices);
  }

  /**
   * Sets the prices localy
   * @param prices
   */
  setPrices(prices: PriceModel[])
  {
    Price.FLEA = prices.find(x => x.type === 'flea').price;
    Price.FLEA_DISCOUNT = prices.find(x => x.type === 'flea').discount_price;

    Price.CAR = prices.find(x => x.type === 'car').price;
    Price.CAR_DISCOUNT = prices.find(x => x.type === 'car').discount_price;

    Price.KINO = prices.find(x => x.type === 'kino').price;
    Price.KINO_DISCOUNT = prices.find(x => x.type === 'kino').discount_price;
  }
}


export interface PriceModel
{
  id: number;
  type: 'flea' | 'car' | 'kino';
  price: number;
  discount_price: number;
}

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { Moment } from 'moment';
import * as moment from 'moment';
import { HelperService } from '../../../services/helper.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FleaMarketService } from '../../../services/flea-market.service';
import { FleaMarketBookingModel } from '../../../models/flea-market.model';
import { STATUS_INFORMAL_RESERVATION } from '../../../constants/statuses';
import { Price } from '../../../constants/price';

@Component({
  selector: 'app-informal-reservation-dialog',
  templateUrl: './informal-reservation-dialog.component.html',
  styleUrls: ['./informal-reservation-dialog.component.scss']
})
export class InformalReservationDialogComponent implements OnInit, OnDestroy
{
  datesForm: FormGroup;
  dates: string[] = [];

  informal_colors = [
    {
      color: '#cc6699',
      value: 'purple'
    },
    {
      color: '#ff6600',
      value: 'red'
    },
    {
      color: '#9999ff',
      value: 'blue'
    },
    {
      color: '#ffffff',
      value: 'white'
    },
  ];
  selected_color = new FormControl('purple');

  constructor(
    private fb: FormBuilder,
    private helper: HelperService,
    @Inject(MAT_DIALOG_DATA) public data: { spots: string[], date: Moment },
    public dialogRef: MatDialogRef<InformalReservationDialogComponent>,
    private fleaMarket: FleaMarketService
  )
  {
    moment().locale('de');
  }

  ngOnInit()
  {
    const yearAgo = moment().locale('de').endOf('week').subtract(52, 'week');

    for (let index = 0; index <= 156; index++) // Iterate over dates to get the 3 year range
    {
      this.dates.push(yearAgo.clone().add(index, 'week').format('dddd, DD.MM.YYYY')); // Push each sunday date to the array of dates
    }
    console.log('Dialog data: ', this.data);

    this.createForm();
  }
  ngOnDestroy(): void
  {
    this.helper.clearNotifications();
  }

  private createForm()
  {
    this.datesForm = this.fb.group({
      dates: this.fb.array([
        new FormGroup({
          date: new FormControl(this.data.date.locale('de').format('dddd, DD.MM.YYYY'), Validators.required)
        })
      ])
    });
  }

  /**
   * Gets the dates form groups
   */
  getDatesControls(): FormGroup[]
  {
    // @ts-ignore
    return (<FormGroup>this.datesForm.get('dates')).controls;
  }

  /**
   * Removes the date control from the formArray on the provided index
   * @param num Index of the form control
   */
  removeDate(num: number)
  {
    (<FormArray>this.datesForm.get('dates')).removeAt(num);
  }

  /**
   * Adds new date control to the formArray
   * @param amount The amount of dates to add
   */
  addNewDate(amount: number = 1)
  {
    // Iterate the amount of time specified
    for (let i = 0; i < amount; i++)
    {
      // Get the index of the last form group control in form array
      const len = this.getDatesControls().length;

      // Get the date value of the date control
      const last_date_value = this.getDatesControls()[len - 1].controls['date'].value;

      // Add a week to the found control
      const value = this.helper.getMomentFromString(last_date_value).add(1, 'week');

      (<FormArray>this.datesForm.get('dates')).push(new FormGroup({
        // Initialize the new form control with a date one week in advance
        date: new FormControl(this.helper.getStringDateFromMoment(value), Validators.required)
      }));
    }
  }

  addUntilEndOfMonth()
  {
    // Get current value
    const date = this.getDatesControls()[this.getDatesControls().length - 1].controls['date'].value;
    // Get current date's end of month
    const date_a = this.helper.getMomentFromString(date).endOf('month');
    // Get current date to moment object
    const date_b = this.helper.getMomentFromString(date);
    // Get the difference in weeks to the end of the month
    const diff = date_a.diff(date_b, 'week');
    // Add the amount of dates in difference
    this.addNewDate(diff);
  }
  addUntilEndOfYear()
  {
    // Get current value
    const date = this.getDatesControls()[this.getDatesControls().length - 1].controls['date'].value;
    // Get current date's end of month
    const date_a = this.helper.getMomentFromString(date).endOf('year');
    // Get current date to moment object
    const date_b = this.helper.getMomentFromString(date);
    // Get the difference in weeks to the end of the month
    const diff = date_a.diff(date_b, 'week');
    // Add the amount of dates in difference
    this.addNewDate(diff);
  }
  addOneYear()
  {
    this.addNewDate(52); // Add 52 weeks
  }

  save()
  {
    if (this.selected_color.value === 'white')
    {
      const spots: { name: string; booking_date: string }[] = [];
      const booking_dates = this.helper.getBookingDates(this.getDatesControls());

      booking_dates.forEach(date =>
      {
        this.data.spots.forEach(spot =>
        {
          spots.push({ name: spot, booking_date: date.date });
        });
      });

      console.log('spots: ', spots);

      this.fleaMarket.removeInformalSpot(spots).subscribe(res =>
      {
        console.log('Remove informal spot response', res);
        this.dialogRef.close({ ok: true });

      }, err => console.log('Remove informal spot error', err));
    }
    else
    {
      const booking: FleaMarketBookingModel = {
        booking_dates: this.helper.getBookingDates(this.getDatesControls()),
        spots: this.data.spots.map(s => ({ name: s })),
        status: STATUS_INFORMAL_RESERVATION,
        discount: false,
        price: Price.FLEA,
        informal_color: this.selected_color.value
      };

      this.fleaMarket.createFleaMarketBooking(booking).subscribe(res =>
      {
        console.log('Booking response', res);
        this.dialogRef.close({ ok: true });

      }, err => console.log('Booking error', err));
    }
  }
}

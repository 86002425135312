import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AuthGuard } from '../../guards/auth.guard';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy
{
  form: FormGroup;
  loading: boolean = false;
  previousURL;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private authGuard: AuthGuard,
    private helper: HelperService
  )
  {
    if (auth.isLoggedIn())
    {
      this.router.navigate(['/schedule']);
    }
  }

  ngOnInit()
  {
    if (this.authGuard.redirectURL)
    {
      this.previousURL = this.authGuard.redirectURL;
      this.authGuard.redirectURL = undefined;
    }
    this.createForm();
    if (window.location.hostname === 'localhost')
    {
      setTimeout(() => {
        this.auth.setData({ username: 'admin' });
        this.router.navigate([this.previousURL ? this.previousURL : '/schedule']);
      }, 0);
    }
  }
  ngOnDestroy()
  {
    this.helper.clearNotifications();
  }

  private createForm()
  {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  login()
  {
    if (this.form.invalid || this.loading)
    {
      return;
    }

    this.loading = true;
    this.auth.login(this.form.controls.username.value, this.form.controls.password.value).subscribe(res =>
    {
      console.log('Login res', res);
      this.auth.setData({ username: this.form.controls.username.value });
      this.loading = false;

      if (this.previousURL)
      {
        this.router.navigate([this.previousURL]);
      }
      else
      {
        this.router.navigate(['/schedule']);
      }


    }, err =>
      {
        this.loading = false;
        console.log('Login error', err);
        this.helper.warningNoti(err.error.message);
      });
  }
}

export const SPOTS = [
  { // Row 1 M
    spots: [
      { name: 'CA1', class: 'spot container-spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'M1', class: 'spot' },
      { name: 'M2', class: 'spot' },
      { name: 'M3', class: 'spot' },
      { name: 'M4', class: 'spot' },
      { name: 'M5', class: 'spot' },
      { name: 'M6', class: 'spot' },
      { name: 'M7', class: 'spot' },
      { name: 'M8', class: 'spot' },
      { name: 'M9', class: 'spot' },
      { name: 'M10', class: 'spot' },
      { name: 'M11', class: 'spot' },
      { name: 'M12', class: 'spot' },
      { name: 'M13', class: 'spot' },
      { name: 'M14', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
    ]
  },
  {
    spots: [
      { name: 'X', class: 'spot-hidden spot-aisle' },
    ]
  },
  { // Row 2 L
    spots: [
      { name: 'CA2', class: 'spot container-spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'L1', class: 'spot' },
      { name: 'L2', class: 'spot' },
      { name: 'L3', class: 'spot' },
      { name: 'L4', class: 'spot' },
      { name: 'L5', class: 'spot' },
      { name: 'L6', class: 'spot' },
      { name: 'L7', class: 'spot' },
      { name: 'L8', class: 'spot' },
      { name: 'L9', class: 'spot' },
      { name: 'L10', class: 'spot' },
      { name: 'L11', class: 'spot' },
      { name: 'L12', class: 'spot' },
      { name: 'L13', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
    ]
  },
  { // Row 3 K
    spots: [
      // { name: 'CA2', class: 'spot container-spot' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'K1', class: 'spot' },
      { name: 'K2', class: 'spot' },
      { name: 'K3', class: 'spot' },
      { name: 'K4', class: 'spot' },
      { name: 'K5', class: 'spot' },
      { name: 'K6', class: 'spot' },
      { name: 'K7', class: 'spot' },
      { name: 'K8', class: 'spot' },
      { name: 'K9', class: 'spot' },
      { name: 'K10', class: 'spot' },
      { name: 'K11', class: 'spot' },
      { name: 'K12', class: 'spot' },
      { name: 'K13', class: 'spot' },
      { name: 'K14', class: 'spot' },
      { name: 'K15', class: 'spot' },
      { name: 'K16', class: 'spot' },
      { name: 'K17', class: 'spot' },
      { name: 'K18', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
    ]
  },

  {
    spots: [
      { name: 'CA3', class: 'spot container-spot move-up' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
    ]
  },

  { // Row 4 J
    spots: [

      { name: 'CA4', class: 'spot container-spot move-up' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },


      { name: 'J1', class: 'spot' },
      { name: 'J2', class: 'spot' },
      { name: 'J3', class: 'spot' },
      { name: 'J4', class: 'spot' },
      { name: 'J5', class: 'spot' },
      { name: 'J6', class: 'spot' },
      { name: 'J7', class: 'spot' },
      { name: 'J8', class: 'spot' },
      { name: 'J9', class: 'spot' },
      { name: 'J10', class: 'spot' },
      { name: 'J11', class: 'spot' },
      { name: 'J12', class: 'spot' },
      { name: 'W1', class: 'spot' },
      { name: 'W2', class: 'spot' },
      { name: 'W3', class: 'spot' },
      { name: 'W4', class: 'spot' },
      { name: 'W5', class: 'spot' },
      { name: 'W6', class: 'spot' },
      { name: 'W7', class: 'spot' },
      { name: 'W8', class: 'spot' },
      { name: 'W9', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

    ]
  },
  { // Row 5 I
    spots: [

      { name: 'CA5  ', class: 'spot container-spot move-down' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'I1', class: 'spot' },
      { name: 'I2', class: 'spot' },
      { name: 'I3', class: 'spot' },
      { name: 'I4', class: 'spot' },
      { name: 'I5', class: 'spot' },
      { name: 'I6', class: 'spot' },
      { name: 'I7', class: 'spot' },
      { name: 'I8', class: 'spot' },
      { name: 'I9', class: 'spot' },
      { name: 'I10', class: 'spot' },
      { name: 'I11', class: 'spot' },
      { name: 'I12', class: 'spot' },
      { name: 'I13', class: 'spot' },
      { name: 'V1', class: 'spot' },
      { name: 'V2', class: 'spot' },
      { name: 'V3', class: 'spot' },
      { name: 'V4', class: 'spot' },
      { name: 'V5', class: 'spot' },
      { name: 'V6', class: 'spot' },
      { name: 'V7', class: 'spot' },
      { name: 'V8', class: 'spot' },
      { name: 'V9', class: 'spot' },
      { name: 'V10', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
    ]
  },
  {
    spots: [
      { name: 'CA6', class: 'spot container-spot move-down' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
    ]
  },
  { // Row 6
    spots: [
      // { name: 'CA7', class: 'spot container-spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'H1', class: 'spot' },
      { name: 'H2', class: 'spot' },
      { name: 'H3', class: 'spot' },
      { name: 'H4', class: 'spot' },
      { name: 'H5', class: 'spot' },
      { name: 'H6', class: 'spot' },
      { name: 'H7', class: 'spot' },
      { name: 'H8', class: 'spot' },
      { name: 'H9', class: 'spot' },
      { name: 'H10', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'U1', class: 'spot' },
      { name: 'U2', class: 'spot' },
      { name: 'U3', class: 'spot' },
      { name: 'U4', class: 'spot' },
      { name: 'U5', class: 'spot' },
      { name: 'U6', class: 'spot' },
      { name: 'U7', class: 'spot' },
      { name: 'U8', class: 'spot' },
      { name: 'U9', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
    ]
  },
  { // Row 7
    spots: [
      { name: 'CA7', class: 'spot container-spot' },
      // { name: 'X', class: 'spot-hidden' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'G1', class: 'spot' },
      { name: 'G2', class: 'spot' },
      { name: 'G3', class: 'spot' },
      { name: 'G4', class: 'spot' },
      { name: 'G5', class: 'spot' },
      { name: 'G6', class: 'spot' },
      { name: 'G7', class: 'spot' },
      { name: 'G8', class: 'spot' },
      { name: 'G9', class: 'spot' },
      { name: 'G10', class: 'spot' },
      { name: 'G11', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'T1', class: 'spot' },
      { name: 'T2', class: 'spot' },
      { name: 'T3', class: 'spot' },
      { name: 'T4', class: 'spot' },
      { name: 'T5', class: 'spot' },
      { name: 'T6', class: 'spot' },
      { name: 'T7', class: 'spot' },
      { name: 'T8', class: 'spot' },
      { name: 'T9', class: 'spot' },
      { name: 'T10', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
    ]
  },
  {
    spots: [
      { name: 'CA8', class: 'spot container-spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
    ]
  },
  { // Row 8
    spots: [
      { name: 'Z4', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'F1', class: 'spot' },
      { name: 'F2', class: 'spot' },
      { name: 'F3', class: 'spot' },
      { name: 'F4', class: 'spot' },
      { name: 'F5', class: 'spot' },
      { name: 'F6', class: 'spot' },
      { name: 'F7', class: 'spot' },
      { name: 'F8', class: 'spot' },
      { name: 'F9', class: 'spot' },
      { name: 'F10', class: 'spot' },
      { name: 'F11', class: 'spot' },
      { name: 'F12', class: 'spot' },
      { name: 'F13', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'S1', class: 'spot' },
      { name: 'S2', class: 'spot' },
      { name: 'S3', class: 'spot' },
      { name: 'S4', class: 'spot' },
      { name: 'S5', class: 'spot' },
      { name: 'S6', class: 'spot' },
      { name: 'S7', class: 'spot' },
      { name: 'S8', class: 'spot' },
      { name: 'S9', class: 'spot' },
      { name: 'S10', class: 'spot' },
      { name: 'S11', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

    ]
  },
  { // Row 9
    spots: [
      { name: 'Z3', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'E1', class: 'spot' },
      { name: 'E2', class: 'spot' },
      { name: 'E3', class: 'spot' },
      { name: 'E4', class: 'spot' },
      { name: 'E5', class: 'spot' },
      { name: 'E6', class: 'spot' },
      { name: 'E7', class: 'spot' },
      { name: 'E8', class: 'spot' },
      { name: 'E9', class: 'spot' },
      { name: 'E10', class: 'spot' },
      { name: 'E11', class: 'spot' },
      { name: 'E12', class: 'spot' },
      { name: 'E13', class: 'spot' },
      { name: 'E14', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'R1', class: 'spot' },
      { name: 'R2', class: 'spot' },
      { name: 'R3', class: 'spot' },
      { name: 'R4', class: 'spot' },
      { name: 'R5', class: 'spot' },
      { name: 'R6', class: 'spot' },
      { name: 'R7', class: 'spot' },
      { name: 'R8', class: 'spot' },
      { name: 'R9', class: 'spot' },
      { name: 'R10', class: 'spot' },
      { name: 'R11', class: 'spot' },
      { name: 'R12', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

    ]
  },
  {
    spots: [
      { name: 'Z2', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
    ]
  },
  { // Row 10
    spots: [
      { name: 'Z1', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'D1', class: 'spot' },
      { name: 'D2', class: 'spot' },
      { name: 'D3', class: 'spot' },
      { name: 'D4', class: 'spot' },
      { name: 'D5', class: 'spot' },
      { name: 'D6', class: 'spot' },
      { name: 'D7', class: 'spot' },
      { name: 'D8', class: 'spot' },
      { name: 'D9', class: 'spot' },
      { name: 'D10', class: 'spot' },
      { name: 'D11', class: 'spot' },
      { name: 'D12', class: 'spot' },
      { name: 'D13', class: 'spot' },
      { name: 'D14', class: 'spot' },
      { name: 'D15', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'Q1', class: 'spot' },
      { name: 'Q2', class: 'spot' },
      { name: 'Q3', class: 'spot' },
      { name: 'Q4', class: 'spot' },
      { name: 'Q5', class: 'spot' },
      { name: 'Q6', class: 'spot' },
      { name: 'Q7', class: 'spot' },
      { name: 'Q8', class: 'spot' },
      { name: 'Q9', class: 'spot' },
      { name: 'Q10', class: 'spot' },
      { name: 'Q11', class: 'spot' },
      { name: 'Q12', class: 'spot' },
      { name: 'Q13', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

    ]
  },
  { // Row 11
    spots: [

      { name: 'CA9', class: 'spot container-spot' },

      { name: 'X', class: 'spot-hidden' },

      { name: 'C1', class: 'spot' },
      { name: 'C2', class: 'spot' },
      { name: 'C3', class: 'spot' },
      { name: 'C4', class: 'spot' },
      { name: 'C5', class: 'spot' },
      { name: 'C6', class: 'spot' },
      { name: 'C7', class: 'spot' },
      { name: 'C8', class: 'spot' },
      { name: 'C9', class: 'spot' },
      { name: 'C10', class: 'spot' },
      { name: 'C11', class: 'spot' },
      { name: 'C12', class: 'spot' },
      { name: 'C13', class: 'spot' },
      { name: 'C14', class: 'spot' },
      { name: 'C15', class: 'spot' },
      { name: 'C16', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'P1', class: 'spot' },
      { name: 'P2', class: 'spot' },
      { name: 'P3', class: 'spot' },
      { name: 'P4', class: 'spot' },
      { name: 'P5', class: 'spot' },
      { name: 'P6', class: 'spot' },
      { name: 'P7', class: 'spot' },
      { name: 'P8', class: 'spot' },
      { name: 'P9', class: 'spot' },
      { name: 'P10', class: 'spot' },
      { name: 'P11', class: 'spot' },
      { name: 'P12', class: 'spot' },
      { name: 'P13', class: 'spot' },
      { name: 'P14', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

    ]
  },
  {
    spots: [
      { name: 'X', class: 'spot-hidden spot-aisle' },
    ]
  },
  { // Row 12
    spots: [
      { name: 'B1', class: 'spot' },
      { name: 'B2', class: 'spot' },
      { name: 'B3', class: 'spot' },
      { name: 'B4', class: 'spot' },
      { name: 'B5', class: 'spot' },
      { name: 'B6', class: 'spot' },
      { name: 'B7', class: 'spot' },
      { name: 'B8', class: 'spot' },
      { name: 'B9', class: 'spot' },
      { name: 'B10', class: 'spot' },
      { name: 'B11', class: 'spot' },
      { name: 'B12', class: 'spot' },
      { name: 'B13', class: 'spot' },
      { name: 'B14', class: 'spot' },
      { name: 'B15', class: 'spot' },
      { name: 'B16', class: 'spot' },
      { name: 'B17', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'O1', class: 'spot' },
      { name: 'O2', class: 'spot' },
      { name: 'O3', class: 'spot' },
      { name: 'O4', class: 'spot' },
      { name: 'O5', class: 'spot' },
      { name: 'O6', class: 'spot' },
      { name: 'O7', class: 'spot' },
      { name: 'O8', class: 'spot' },
      { name: 'O9', class: 'spot' },
      { name: 'O10', class: 'spot' },
      { name: 'O11', class: 'spot' },
      { name: 'O12', class: 'spot' },
      { name: 'O13', class: 'spot' },
      { name: 'O14', class: 'spot' },
      { name: 'O15', class: 'spot' },

    ]
  },
  { // Row 13
    spots: [
      { name: 'A1', class: 'spot' },
      { name: 'A2', class: 'spot' },
      { name: 'A3', class: 'spot' },
      { name: 'A4', class: 'spot' },
      { name: 'A5', class: 'spot' },
      { name: 'A6', class: 'spot' },
      { name: 'A7', class: 'spot' },
      { name: 'A8', class: 'spot' },
      { name: 'A9', class: 'spot' },
      { name: 'A10', class: 'spot' },
      { name: 'A11', class: 'spot' },
      { name: 'A12', class: 'spot' },
      { name: 'A13', class: 'spot' },
      { name: 'A14', class: 'spot' },
      { name: 'A15', class: 'spot' },
      { name: 'A16', class: 'spot' },
      { name: 'A17', class: 'spot' },
      { name: 'A18', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'N1', class: 'spot' },
      { name: 'N2', class: 'spot' },
      { name: 'N3', class: 'spot' },
      { name: 'N4', class: 'spot' },
      { name: 'N5', class: 'spot' },
      { name: 'N6', class: 'spot' },
      { name: 'N7', class: 'spot' },
      { name: 'N8', class: 'spot' },
      { name: 'N9', class: 'spot' },
      { name: 'N10', class: 'spot' },
      { name: 'N11', class: 'spot' },
      { name: 'N12', class: 'spot' },
      { name: 'N13', class: 'spot' },
      { name: 'N14', class: 'spot' },
      { name: 'N15', class: 'spot' },
      { name: 'N16', class: 'spot' },
    ]
  },

  {
    spots: [
      { name: 'X', class: 'spot-hidden spot-aisle' },
    ]
  },

  { // Row 15
    spots: [
      { name: 'X1', class: 'spot' },
      { name: 'X2', class: 'spot' },
      { name: 'X3', class: 'spot' },
      { name: 'X4', class: 'spot' },
      { name: 'X5', class: 'spot' },
      { name: 'X6', class: 'spot' },
      { name: 'X7', class: 'spot' },
      { name: 'X8', class: 'spot' },
      { name: 'X9', class: 'spot' },
      { name: 'X10', class: 'spot' },
      { name: 'X11', class: 'spot' },
      { name: 'X12', class: 'spot' },
      { name: 'X13', class: 'spot' },
      { name: 'X14', class: 'spot' },
      { name: 'X15', class: 'spot' },
      { name: 'X16', class: 'spot' },
      { name: 'X17', class: 'spot' },
      { name: 'X18', class: 'spot' },
      { name: 'X19', class: 'spot' },
      { name: 'X20', class: 'spot' },
      { name: 'X21', class: 'spot' },
      { name: 'X22', class: 'spot' },
      { name: 'X23', class: 'spot' },
      { name: 'X24', class: 'spot' },
      { name: 'X25', class: 'spot' },
      { name: 'X26', class: 'spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

      { name: 'Y1', class: 'spot' },
      { name: 'Y2', class: 'spot' },
      { name: 'Y3', class: 'spot' },
      { name: 'Y4', class: 'spot' },
      { name: 'Y5', class: 'spot' },
      { name: 'Y6', class: 'spot' },
      { name: 'Y7', class: 'spot' },
      { name: 'Y8', class: 'spot' },
      { name: 'Y9', class: 'spot' },
      { name: 'Y10', class: 'spot' },
      { name: 'Y11', class: 'spot' },
      { name: 'Y12', class: 'spot' },
      { name: 'Y13', class: 'spot' },
      { name: 'Y14', class: 'spot' },
      { name: 'Y15', class: 'spot' },
      { name: 'Y16', class: 'spot' },
      { name: 'Y17', class: 'spot' },

    ]
  },

  {
    spots: [
      { name: 'X', class: 'spot-hidden spot-aisle' },
    ]
  },
  {
    spots: [
      { name: 'X', class: 'spot-hidden spot-aisle' },
    ]
  },

  { // Row 16
    spots: [
      { name: 'CC10', class: 'spot container-spot' },
      { name: 'CC9', class: 'spot container-spot' },
      { name: 'CC8', class: 'spot container-spot' },
      { name: 'CC7', class: 'spot container-spot' },
      { name: 'CC6', class: 'spot container-spot' },
      { name: 'CC5', class: 'spot container-spot' },
      { name: 'CC4', class: 'spot container-spot' },
      { name: 'CC3', class: 'spot container-spot' },
      { name: 'CC2', class: 'spot container-spot' },
      { name: 'CC1', class: 'spot container-spot' },
      
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

    ]
  },

  {
    spots: [
      { name: 'X', class: 'spot-hidden spot-aisle' },
    ]
  },

  { // Row 17
    spots: [
      { name: 'CA10', class: 'spot container-spot' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'CA11', class: 'spot container-spot' },
      { name: 'CA12', class: 'spot container-spot' },

      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },
      { name: 'X', class: 'spot-hidden' },

    ]
  }

];


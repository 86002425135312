import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss']
})
export class EventLogComponent implements OnInit
{
  private _eventLog: EventLog[] = [];
  @Input()
  set eventLog(v: EventLog[]) { this._eventLog = v.reverse(); }
  get eventLog() { return this._eventLog; }

  constructor() { }

  ngOnInit()
  {
  }

}

export interface EventLog
{
  total: number;
  created_at: string;
  invoice: number;
  event_type: string;
}

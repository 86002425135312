import { Injectable } from '@angular/core';
import { NavLinksModel } from '../models/nav-links.model';

@Injectable({
  providedIn: 'root'
})
export class NavService
{
  navLinks: NavLinksModel = {
    schedule_view: {
      url: '/schedule',
      name: 'NAV.schedule_view'
    },
    table_view: {
      url: '/table-view',
      name: 'NAV.table_view'
    },
    car_market: {
      url: '/car-market',
      name: 'NAV.car_market'
    },
    kino: {
      url: '/kino',
      name: 'NAV.movie_teather'
    }
  };

  constructor()
  {}
}

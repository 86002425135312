import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { Moment } from 'moment';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FleaMarketService } from '../../services/flea-market.service';
import { SPOTS } from '../../constants/spots';
import { HelperService } from '../../services/helper.service';
import { FleaSpotsModel, QuickFleaMarketBookingModel, SpotModel, FleaSpot } from '../../models/flea-market.model';
import { TranslateService } from '@ngx-translate/core';
import { STATUS_RESERVED, STATUS_PAID, STATUS_PARTIALLY_PAID, STATUS_NOT_PAID, STATUS_FREE } from '../../constants/statuses';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';
import { QuickBookingDialogComponent } from './quick-booking-dialog/quick-booking-dialog.component';
import { InformalReservationDialogComponent } from './informal-reservation-dialog/informal-reservation-dialog.component';
import { BookingDetailsDialogComponent } from './booking-details-dialog/booking-details-dialog.component';
import { LoadingService } from '../../services/loading.service';
import { ChangeBookingTypeDialogComponent } from './change-booking-type-dialog/change-booking-type-dialog.component';
import { Price } from '../../constants/price';

@Component({
  selector: 'app-schedule-view',
  templateUrl: './schedule-view.component.html',
  styleUrls: ['./schedule-view.component.scss']
})
export class ScheduleViewComponent implements OnInit, OnDestroy
{
  loadingData = false;
  date_Control: FormControl;
  spots: FleaSpotsModel[] = JSON.parse(JSON.stringify(SPOTS));

  selectedSpots: string[] = [];

  constructor(
    public nav: NavService,
    private router: Router,
    private fleaMarket: FleaMarketService,
    private helper: HelperService,
    private i18n: TranslateService,
    private dialog: MatDialog,
    private ls: LoadingService
  )
  {
    if (moment().day() === 0) // Check if it is sunday
    {
      this.date_Control = new FormControl(moment());
    }
    else // If not set date to next sunday
    {
      this.date_Control = new FormControl(moment().day(0 + 7));
    }
  }

  ngOnInit()
  {
    this.fleaMarket.selected_places = [];
    this.getSpots();

    this.date_Control.valueChanges.subscribe(() =>
    {
      this.getSpots();
    });
  }
  ngOnDestroy(): void
  {
    this.helper.clearNotifications();
  }

  getSpots()
  {
    this.loadingData = true;
    this.fleaMarket.selected_places = [];
    this.selectedSpots = [];
    this.resetSpots();

    this.fleaMarket.getSpotsForDate(this.helper.convertDateToBackendFormat(this.date_Control.value))
      .subscribe(res =>
      {
        console.log('Spots response: ', res.reverse());
        this.spots.forEach(row =>
        {
          row.spots.forEach(spot =>
          {
            const found_spot = res.find(x => x.name === spot.name);
            if (found_spot)
            {
              spot.status = found_spot.status;
              spot.discount = found_spot.discount;
              spot.booking_id = found_spot.booking_id;
              spot.informalColor = found_spot.informalColor;
            }
          });
        });

        this.loadingData = false;
      }, err =>
        {
          console.log('Error gettings spots', err);
          this.helper.errorNoti(this.i18n.instant('ERR.error_getting_spots'));
          this.loadingData = false;
        });
  }

  /**
   * Resets the main spots view to the empty values
   */
  resetSpots()
  {
    this.spots = null;
    this.spots = JSON.parse(JSON.stringify(SPOTS)); // Reset the spots
  }

  /**
   * Filters out all other days except sundays
   */
  dateFilter = (d: Moment): boolean =>
  {
    const day = d.day();
    return day === 0; // Only enable sundays
  }

  /**
   * Opens the detailed booking page with the selected spots
   */
  addBooking()
  {
    this.fleaMarket.selected_places = this.selectedSpots.map(s => ({ name: s }));
    console.log('Selected', this.fleaMarket.selected_places);
    this.router.navigate(['/booking/add']);
  }

  /**
   * Adds clicked spot name to the selectedSpots array
   * @param spot
   */
  selectSpot(spot: FleaSpot)
  {
    if (spot.status !== undefined && (spot.status === STATUS_PAID || spot.status === STATUS_PARTIALLY_PAID))
    {
      this.ls.loading = true;
      this.fleaMarket.getBookingByID(Number.parseInt(spot.booking_id, 10)).subscribe(res =>
      {
        setTimeout(() => {
          this.ls.loading = false;
        }, 0);
        const dialogRef = this.dialog.open(BookingDetailsDialogComponent, { data: res, autoFocus: false });

        dialogRef.afterClosed().subscribe(dialog_res =>
        {
          if (dialog_res && dialog_res.ok)
          {
            this.getSpots();
          }
        });
      });
    }
    else if (spot.status !== undefined &&
            (spot.status === STATUS_RESERVED || spot.status === STATUS_NOT_PAID || spot.status === STATUS_FREE))
    {
      this.ls.loading = true;
      this.fleaMarket.getBookingByID(Number.parseInt(spot.booking_id, 10)).subscribe(res =>
      {
        setTimeout(() => {
          this.ls.loading = false;
        }, 0);
        const dialogRef = this.dialog.open(ChangeBookingTypeDialogComponent, { data: res, autoFocus: false });

        dialogRef.afterClosed().subscribe(dialog_res =>
        {
          if (dialog_res && dialog_res.ok)
          {
            this.getSpots();
          }
        });
      });
    }
    else // Spot is not reserved or booked, mark it as selected
    {
      if (this.selectedSpots.includes(spot.name))
      {
        this.selectedSpots = this.selectedSpots.filter(s => !(s === spot.name));
      }
      else
      {
        this.selectedSpots.push(spot.name);
      }
    }
  }

  quickBooking()
  {
    const data = {
      spots: this.selectedSpots,
      date: this.helper.convertDateToBackendFormat(this.date_Control.value)
    };
    const ref = this.dialog.open(QuickBookingDialogComponent, { autoFocus: false, data, minWidth: 600 });
    ref.afterClosed().subscribe(res =>
    {
      console.log('Quick booking dialog res', res);
      if (res && res.ok)
      {
        this.selectedSpots = [];
        this.getSpots();
      }
    });
  }

  /**
   * Adds a quick reservation by passing the selected spots to the api
   * with full ticket price and RESERVED status
   */
  quickReservation()
  {
    const booking: QuickFleaMarketBookingModel = {
      price: Price.FLEA,
      discount: false,
      status: STATUS_RESERVED,
      booking_dates: [{ date: this.helper.convertDateToBackendFormat(this.date_Control.value) }],
      spots: this.selectedSpots.map(s => ({ name: s }))
    };

    this.fleaMarket.createFleaMarketQuickReservation(booking).subscribe(res =>
    {
      console.log('Successful quick reservation', res);
      this.helper.successNoti(this.i18n.instant('NOTI.reservations_added'));
      this.selectedSpots = [];
      this.getSpots();

    }, err =>
      {
        console.log('Error with quick reservation: ', err);
        this.helper.errorNoti(this.i18n.instant('NOTI.error_saving_reservation'), err.error.message);
      });
  }

  informalReservation()
  {
    const data = {
      spots: this.selectedSpots,
      date: this.date_Control.value
    };
    const ref = this.dialog.open(InformalReservationDialogComponent, { data, autoFocus: false, minWidth: 400 });
    ref.afterClosed().subscribe(res =>
    {
      console.log('Informal reservation dialog response: ', res);
      if (res && res.ok)
      {
        this.selectedSpots = [];
        this.getSpots();
      }
    });
  }

  print()
  {
    window.print();
  }
}

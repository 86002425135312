import { Injectable } from '@angular/core';
import { ApiDefinition } from '../constants/api.def';
import { HttpClient } from '@angular/common/http';
import { CarMarketTableResponseModel, CarMarketBookingModelResponse, CarMarketBookingModel } from '../models/car-market.model';
import { Observable } from 'rxjs';
import { InvoiceResponse } from '../models/invoice.model';

@Injectable({
  providedIn: 'root'
})
export class CarMarketService
{
  api: ApiDefinition = new ApiDefinition();

  constructor(
    private http: HttpClient
  )
  { }

  /**
   * Get car market table data
   * /booking/car
   * @param fromDate Start date YYYY-MM-DD format
   * @param toDate End date YYYY-MM-DD format
   */
  getTableData(fromDate: string, toDate: string): Observable<CarMarketTableResponseModel>
  {
    const params = { fromDate, toDate };
    return this.http.get<CarMarketTableResponseModel>(this.api.car_booking + '/table', { params });
  }

  /**
   * GET /booking/car/pdf
   * gets a pdf file
   * @param fromDate Start date YYYY-MM-DD format
   * @param toDate End date YYYY-MM-DD format
   * @returns pdf file
   */
  getPDF(fromDate: string, toDate: string)
  {
    const params = { fromDate, toDate };
    return this.http.get(this.api.car_booking + '/pdf', { params, responseType: 'blob' });
  }

  /**
   * GET /booking/car/excel
   * gets a excel file
   * @param fromDate Start date YYYY-MM-DD format
   * @param toDate End date YYYY-MM-DD format
   * @returns excel file
   */
  getExcel(fromDate: string, toDate: string)
  {
    const params = { fromDate, toDate };
    return this.http.get(this.api.car_booking + '/excel', { params, responseType: 'blob' });
  }

  /**
   * Create a new Carmarket Booking
   * @param body CarMarketBookingModel
   */
  createCarMarketBooking(body: CarMarketBookingModel): Observable<CarMarketBookingModelResponse>
  {
    return this.http.post<CarMarketBookingModelResponse>(this.api.car_booking, body);
  }

  /**
   * Get booking details
   *
   * /booking/car/{id}/details
   * @param id
   */
  getBookingByID(id: number): Observable<CarMarketBookingModelResponse>
  {
    return this.http.get<CarMarketBookingModelResponse>(this.api.car_booking + `/${id}/details`);
  }

  /**
   * Update car market booking
   *
   * PUT /booking/car
   * @param booking
   */
  updateCarMarketBooking(booking: CarMarketBookingModel): Observable<CarMarketBookingModelResponse>
  {
    return this.http.put<CarMarketBookingModelResponse>(this.api.car_booking, booking);
  }

  /**
   * Uploads an image
   * @param img
   */
  uploadImage(img: string): Observable<{ url: string }>
  {
    return this.http.post<{ url: string }>(this.api.car_booking + '/image', { img });
  }

  /**
   * Create car market invoice
   * @param booking_id
   * @param amount_given
   */
  createCarMarketInvoice(booking_id: number, amount_given: number): Observable<InvoiceResponse>
  {
    return this.http.post<InvoiceResponse>(this.api.car_booking + `/${booking_id}/invoice`, { amount_given });
  }

  stornoCarMarketBooking(booking_id: number): Observable<InvoiceResponse>
  {
    return this.http.post<InvoiceResponse>(this.api.car_booking + `/${booking_id}/storno`, { booking_id });
  }

  /**
   * Gets the invoice details
   * @param invoice_id
   */
  getCarInvoice(invoice_id: number): Observable<InvoiceResponse>
  {
    return this.http.get<InvoiceResponse>(this.api.invoice + `/${invoice_id}/details`);
  }
}

import { environment } from '../../environments/environment';

export class ApiDefinition
{
  domain: string;
  /**
   * /booking/kino
   */
  kino_booking: string;
  /**
   * /booking/flea
   */
  flea_booking: string;
  /**
   * /booking/car
   */
  car_booking: string;
  /**
   * /prices
   */
  prices: string;
  /**
   * /invoice
   */
  invoice: string;

  /**
   * /maintenance
   */
  maintenance: string;

  constructor(api_domain = environment.apiUrl)
  {
    this.domain = api_domain;

    this.kino_booking = this.domain + 'booking/kino';
    this.flea_booking = this.domain + 'booking/flea';
    this.car_booking = this.domain + 'booking/car';
    this.prices = this.domain + 'prices';
    this.invoice = this.domain + 'invoice';
    this.maintenance = this.domain + 'maintenance';
  }
}

// http://autokinov2-api.q-tests.com
// https://autokinov2.q-tests.com/api/

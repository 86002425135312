import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../node_modules/@angular/material';
import { InvoiceResponse } from '../../models/invoice.model';
import { HelperService } from '../../services/helper.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-flea-invoice-print',
  templateUrl: './flea-invoice-print.component.html',
  styleUrls: ['./flea-invoice-print.component.scss']
})
export class FleaInvoicePrintComponent implements OnInit
{
  mymoment = moment;
  abs = Math.abs;

  imgFile: SafeResourceUrl;
  imgLoaded = false;

  bookings: { spotAmount: number; date: string; ticketPrice: number }[] = [];

  bookedSpots: string = '';

  constructor(
    public dialogRef: MatDialogRef<FleaInvoicePrintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvoiceResponse,
    private helper: HelperService,
    private sanitize: DomSanitizer
  )
  {
    this.mymoment().locale('de');
  }

  ngOnInit()
  {
    console.log('Data', this.data);

    this.data.fleaMarketBooking.booking_dates.forEach(date =>
    {
      this.bookings.push({
        spotAmount: this.data.fleaMarketBooking.spots.length,
        date: this.mymoment(date.date).format('DD.MM.YYYY.'),
        ticketPrice: this.helper.getTicketPriceBasedOnTotal(
          this.data.total, this.data.fleaMarketBooking.spots.length, this.data.fleaMarketBooking.booking_dates.length
        )
      });
    });

    this.bookedSpots = this.data.fleaMarketBooking.spots.map(s => s.name).toString();

    if (this.data.qr_image)
    {
      this.imgFile = this.sanitize.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.data.qr_image);
    }
    else if (this.data.qr_url)
    {
      this.helper.getBase64ImageFromUrl(this.data.qr_url)
        .then((result: string) =>
        {
          this.imgFile = this.sanitize.bypassSecurityTrustResourceUrl(result);
        })
        .catch(err => console.error(err));
    }
    else
    {
      this.imgFile = null;
      this.imgLoaded = true;
      setTimeout(() => {
        this.printInvoice();
      }, 100);
    }
  }

  printInvoice()
  {
    try
    {
      this.imgLoaded = true;
      const div = document.getElementById('print-content');

      const mywindow = window.open('', '_blank');
      mywindow.document.write('<html><head><title>' + document.title + '</title><style>*{font-size:10px}body{width: 1.8in; font-family:Arial;} table{width:100%}</style>');
      mywindow.document.write('</head><body>');
      mywindow.document.write(div.innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      // this.dialogRef.close();
    }
    catch (error)
    {
      console.log('Error has occured when trying to open a new tab: \n\n', error);
    }
  }
}

import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { FormControl, Validators } from '@angular/forms';

import * as moment from 'moment';
import { STATUSES, STATUS_INFORMAL_RESERVATION, STATUS_RESERVED, STATUS_FREE } from '../../constants/statuses';
import { ORIGINS } from '../../constants/origins';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CarMarketTableResponseModel, CarMarketTableRowModel } from '../../models/car-market.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CarMarketService } from '../../services/car-market.service';
import { DownloadFileService } from '../../services/download-file.service';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-car-market',
  templateUrl: './car-market.component.html',
  styleUrls: ['./car-market.component.scss']
})
export class CarMarketComponent implements OnInit, OnDestroy
{
  loading = false;

  date_from_Control: FormControl;
  date_to_Control: FormControl;
  status_Control: FormControl;
  origin_Control: FormControl;

  statuses = STATUSES.filter(s => s.id !== STATUS_INFORMAL_RESERVATION && s.id !== STATUS_RESERVED && s.id !== STATUS_FREE);
  origins = ORIGINS;

  // Table
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayedColumns = ['id', 'date', 'status', 'total', 'customer', 'edit'];

  data: CarMarketTableResponseModel;
  tableData: CarMarketTableRowModel[] = [];

  constructor(
    public nav: NavService,
    private carMarketService: CarMarketService,
    private downloadService: DownloadFileService,
    private helper: HelperService,
    private i18n: TranslateService
  )
  {
  }

  ngOnInit()
  {
    this.createFormControls();
  }
  ngOnDestroy(): void
  {
    this.helper.clearNotifications();
  }

  private createFormControls()
  {
    if (moment().day() === 0) // Check if it is sunday
    {
      this.date_from_Control = new FormControl(moment());
      this.date_to_Control = new FormControl(moment());
    }
    else // If not set date to next sunday
    {
      this.date_from_Control = new FormControl(moment().day(0 + 7));
      this.date_to_Control = new FormControl(moment().day(0 + 7));
    }
    this.status_Control = new FormControl([-1], [Validators.required]);
    this.origin_Control = new FormControl(this.origins[0].id, [Validators.required]);

    this.getData();
  }

  /**
   * Resets the filters
   */
  resetFilters()
  {
    this.createFormControls();
  }

  fillSource()
  {
    this.dataSource.data = this.tableData;
  }

  getData()
  {
    this.loading = true;
    this.carMarketService.getTableData(this.date_from_Control.value.format('YYYY-MM-DD'), this.date_to_Control.value.format('YYYY-MM-DD'))
    .subscribe((res) =>
    {
      console.log('Car table:', res);
      this.data = res;
      this.tableData = res.data;
      this.fillSource();
      this.loading = false;
      setTimeout(() => {
        this.dataSource.sort = this.sort;
      }, 0);

    }, (err: HttpErrorResponse) =>
    {
      this.helper.infoNoti(this.i18n.instant('NOTI.no_booking_in_date_range'));
      this.tableData = [];
      this.dataSource.data = [];

      this.loading = false;
      console.log(err);
    });
  }

  /**
   * Results filter for table
   */
  filterResults()
  {
    let selectedValue: number[] = this.status_Control.value;

    if (selectedValue.length >= 1)
    {
      if (selectedValue.includes(-1))
      {
        // If All is selected, filter it out
        this.status_Control.setValue(selectedValue.filter(x => x !== -1));
        selectedValue = this.status_Control.value;
      }

      console.log('Selected', selectedValue);
      console.log('Table data:', this.tableData);
      this.dataSource.data = this.tableData.filter(row => selectedValue.includes(row.status));
    }
    else if (selectedValue.length < 1 && !selectedValue.includes(-1))
    {
      this.status_Control.setValue([-1]);
      this.dataSource.data = this.tableData;
    }
  }
  resetStatusSelection()
  {
    this.status_Control.setValue([-1]);
    this.dataSource.data = this.tableData;
  }

  pdf()
  {
    this.carMarketService.getPDF(this.date_from_Control.value.format('YYYY-MM-DD'), this.date_to_Control.value.format('YYYY-MM-DD'))
    .subscribe((res: Blob) =>
    {
      const title = 'Automarkt report ' + this.date_from_Control.value.format('YYYY-MM-DD') + ' ' +  this.date_to_Control.value.format('YYYY-MM-DD');
      this.downloadService.download(res, title, '.pdf');

    }, (err: HttpErrorResponse) =>
    {
      console.log(err);
    });
  }
  excel()
  {
    this.carMarketService.getExcel(this.date_from_Control.value.format('YYYY-MM-DD'), this.date_to_Control.value.format('YYYY-MM-DD'))
    .subscribe((res: any) =>
    {
      const title = 'Automarkt report ' + this.date_from_Control.value.format('YYYY-MM-DD') + ' ' +  this.date_to_Control.value.format('YYYY-MM-DD');
      this.downloadService.download(res, title, '.xlsx');

    }, (err: HttpErrorResponse) =>
    {
      console.log(err);
    });
  }
  /**
   * print table with printer
   */
  print()
  {
    window.print();
  }

  getStatusColor(element: CarMarketTableRowModel): string
  {
    const found = STATUSES.find(x => x.id === element.status);
    return found ? found.color : '';
  }
  getStatusTooltip(element: CarMarketTableRowModel): string
  {
    const found = STATUSES.find(x => x.id === element.status);
    return found ? found.name : '';
  }

}

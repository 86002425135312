import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiDefinition } from '../constants/api.def';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService
{
  user;
  api = new ApiDefinition();

  constructor(
    private http: HttpClient,
    private router: Router
  )
  {
    this.getData();
  }

  isLoggedIn()
  {
    return this.user ? true : false;
  }

  login(username: string, password: string)
  {
    return this.http.post(this.api.domain + 'login', JSON.stringify({ username, password }));
  }

  logout()
  {
    this.user = null;
    this.router.navigate(['/login']);
  }

  setData(user)
  {
    this.user = user;
    // localStorage.setItem('autokino_user', JSON.stringify(this.user));
  }

  getData()
  {
    // this.user = JSON.parse(localStorage.getItem('autokino_user'));
  }
}

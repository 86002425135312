import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Moment } from 'moment';
import * as moment from 'moment';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { STATUSES, STATUS_NOT_PAID, STATUS_PAID, STATUS_STORNO, STATUS_FREE, STATUS_RESERVED } from '../../../constants/statuses';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { FleaMarketService } from '../../../services/flea-market.service';
import { FleaMarketBookingResponseModel, FleaMarketBookingModel } from '../../../models/flea-market.model';
import { HelperService } from '../../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangePlacesDialogComponent } from '../change-places-dialog/change-places-dialog.component';
import { Price } from '../../../constants/price';
import { CATEGORIES } from '../../../constants/categories';
import { FleaInvoicePrintComponent } from '../../flea-invoice-print/flea-invoice-print.component';
import { ChangesNotSavedDialogComponent } from '../../changes-not-saved-dialog/changes-not-saved-dialog.component';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent implements OnInit, OnDestroy
{
  loadingData = false;
  spotsChanged = false;

  dates: string[] = [];
  addAmountDropdown: number[] = [1, 2, 3, 4, 5, 10];

  datesForm: FormGroup;
  ticketPriceForm: FormGroup;
  userInfoForm: FormGroup;

  categories = CATEGORIES;
  status;
  status_free = STATUS_FREE;

  bookingID;
  booking: FleaMarketBookingResponseModel;
  booking_notes: string = '';
  isReservation = false;

  topayControl: FormControl;

  abs = Math.abs;

  get unitPrice() { return this.ticketPriceForm.controls.unit_price as FormControl; }
  get sum() { return this.ticketPriceForm.controls.sum as FormControl; }

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ls: LoadingService,
    private fleaMarket: FleaMarketService,
    private helper: HelperService,
    private i18n: TranslateService,
    private dialog: MatDialog,
    private router: Router,
  )
  { }

  ngOnInit()
  {
    const yearAgo = moment().locale('de').endOf('week').subtract(52, 'week');

    for (let index = 0; index <= 156; index++) // Iterate over dates to get the 3 year range
    {
      this.dates.push(yearAgo.clone().add(index, 'week').format('dddd, DD.MM.YYYY')); // Push each sunday date to the array of dates
    }

    this.bookingID = this.route.snapshot.params.id;
    this.ls.loading = true;
    this.loadingData = true;

    if (this.bookingID) // If booking id exist, that means that we are editing an existing booking
    {
      this.getBooking();
    }
    else if (this.fleaMarket.selected_places) // Else a new booking is being made
    {
      this.createForm();
      this.calcBothPrices();
      this.loadingData = false;
      this.ls.loading = false;
    }
    else
    {
      this.location.back();
      this.helper.warningNoti(this.i18n.instant('NOTI.no_selected_places'));
    }
  }
  ngOnDestroy(): void
  {
    this.helper.clearNotifications();
  }

  /**
   * Calls the service to get booking details, and creates prefilled form with fetched data
   */
  private getBooking()
  {
    this.fleaMarket.getBookingByID(this.bookingID).subscribe(res =>
    {
      console.log('Booking details', res);
      this.booking = res;
      this.booking_notes = this.booking.note;
      this.status = this.getStatus(this.booking.status);
      this.createForm(res);
      this.fleaMarket.selected_places = this.booking.spots;
      this.calcBothPrices();
      this.ls.loading = false;
      this.loadingData = false;

    }, err =>
      {
        console.log(err);
        this.ls.loading = false;
        this.helper.errorNoti(this.i18n.instant('NOTI.error_getting_booking'));
        this.goBack();
      });
  }

  private createForm(booking?: FleaMarketBookingResponseModel)
  {
    if (booking) // If the booking is being edited
    {
      // Initialize date form
      this.datesForm = this.fb.group({
        dates: this.fb.array(this.createDatesFormGroupArray(booking.booking_dates))
      });

      // Initialize ticket price form
      this.ticketPriceForm = this.fb.group({
        price_type: [{ value: booking.discount ? true : false, disabled: true }, Validators.required],
        unit_price: [{ value: booking.price.toFixed(2), disabled: true },
                       Validators.compose([Validators.required, Validators.min(0)])],
        sum: [{ value: booking.total.toFixed(2), disabled: true }],
        place_number: [{ value: booking.spots.map(x => x.name).slice(0).toString(), disabled: true }, Validators.required]
      });

      if (booking.customer) // Initialize the form if customer info exists
      {
        this.userInfoForm = this.fb.group({
          first_name: [booking.customer.first_name || ''],
          last_name: [booking.customer.last_name || ''],
          email: [booking.customer.email || '', [Validators.email]],
          phone_number: [booking.customer.phone || ''],
          company: [booking.customer.company || ''],
          street: [booking.customer.streetname || ''],
          street_number: [booking.customer.street_number || ''],
          place: [booking.customer.city || ''],
          post_code: [booking.customer.post_code || ''],
          category: [booking.customer.goods || '']
        });
      }
      else // Else initialize with empty values
      {
        this.userInfoForm = this.fb.group({
          first_name: [''],
          last_name: [''],
          email: ['', [Validators.email]],
          phone_number: [''],
          company: [''],
          street: [''],
          street_number: [''],
          place: [''],
          post_code: [''],
          category: ['']
        });
      }

      if (booking.status === STATUS_RESERVED)
      {
        this.isReservation = true;

        this.ticketPriceForm.enable();
        this.ticketPriceForm.controls.place_number.disable();
        this.datesForm.enable();
      }

      this.topayControl = new FormControl((booking.total - booking.payed_amount).toFixed(2), Validators.compose([
        Validators.required,
        Validators.min(0.01)
      ]));
    }
    else // If a new booking is being made
    {
      // Initialize date form
      this.datesForm = this.fb.group({
        dates: this.fb.array([
          new FormGroup({
            date: new FormControl(moment().locale('de').endOf('week').format('dddd, DD.MM.YYYY'), Validators.required)
          })
        ])
      });

      // Initialize ticket price form
      this.ticketPriceForm = this.fb.group({
        price_type: [false, Validators.required],
        unit_price: [Price.FLEA, Validators.compose([Validators.required, Validators.min(0)])],
        sum: [Price.FLEA, Validators.compose([Validators.required, Validators.min(0)])],
        place_number: [{ value: this.fleaMarket.selected_places.map(x => x.name).toString(), disabled: true }, Validators.required]
      });

      // Initialize user form
      this.userInfoForm = this.fb.group({
        first_name: [''],
        last_name: [''],
        email: ['', [Validators.email]],
        phone_number: [''],
        company: [''],
        street: [''],
        street_number: [''],
        place: [''],
        post_code: [''],
        category: ['']
      });
    }

    this.ticketPriceForm.controls.price_type.valueChanges.subscribe(value =>
    {
      if (value === false)
      {
        this.unitPrice.setValue(Price.FLEA);
      }
      else
      {
        this.unitPrice.setValue(Price.FLEA_DISCOUNT);
      }
    });
    this.datesForm.valueChanges.subscribe(value =>
    {
      this.calcBothPrices();
    });

    this.unitPrice.valueChanges.subscribe(v =>
    {
      if (v < 0)
      {
        this.unitPrice.setValue(0, { emitEvent: false });
      }
      this.calculateSum();
    });
    this.sum.valueChanges.subscribe(v =>
    {
      if (v < 0)
      {
        this.sum.setValue(0, { emitEvent: false });
      }
      this.calculateTicketPrice();
    });

    this.getUserinfoControl('category').valueChanges.subscribe(value =>
    {
      const cat = this.categories.find(c => c.id === value);
      this.ticketPriceForm.controls.price_type.setValue(cat.dicounted);
    });
  }

  private calculateSum()
  {
    this.sum.setValue(
      (this.unitPrice.value * this.fleaMarket.selected_places.length * this.getDatesControls().length).toFixed(2),
      { emitEvent: false }
    );
  }

  private calculateTicketPrice()
  {
    this.unitPrice.setValue(
      this.helper.getTicketPriceBasedOnTotal(
        this.sum.value, this.fleaMarket.selected_places.length, this.getDatesControls().length
      ).toFixed(2),
      { emitEvent: false }
    );
  }


  private calcBothPrices()
  {
    this.calculateSum();
    this.calculateTicketPrice();
  }

  /**
   * Returns a form group array with formatted dates
   * @param dates
   */
  private createDatesFormGroupArray(dates: { id: number, date: string }[]): FormGroup[]
  {
    const formGroupArray: FormGroup[] = [];

    dates.forEach((element, i) =>
    {
      formGroupArray.push(
        new FormGroup({
          date: new FormControl(
            { value: moment(element.date, 'YYYY-MM-DD').locale('de').format('dddd, DD.MM.YYYY'), disabled: true },
            Validators.required)
        })
      );
    });
    return formGroupArray;
  }

  /**
   * Gets the dates form groups
   */
  getDatesControls(): FormGroup[]
  {
    // @ts-ignore
    return (<FormGroup>this.datesForm.get('dates')).controls;
  }

  getUserinfoControl(field: string): FormControl
  {
    return this.userInfoForm.controls[field] as FormControl;
  }

  goBack()
  {
    if (this.datesForm.touched || this.ticketPriceForm.touched || this.userInfoForm.touched || this.spotsChanged)
    {
      const dialogRef = this.dialog.open(ChangesNotSavedDialogComponent, { autoFocus: false, minWidth: 300 });
      dialogRef.afterClosed().subscribe(dialog_response =>
      {
        console.log(dialog_response);
        if (dialog_response)
        {
          this.router.navigate(['/schedule']);
        }
      });
    }
    else
    {
      this.router.navigate(['/schedule']);
    }
  }

  /**
   * Adds new date control to the formArray
   * @param amount The amount of dates to add
   */
  addNewDate(amount: number = 1)
  {
    // Iterate the amount of time specified
    for (let i = 0; i < amount; i++)
    {
      // Get the index of the last form group control in form array
      const len = this.getDatesControls().length;

      // Get the date value of the date control
      const last_date_value = this.getDatesControls()[len - 1].controls['date'].value;

      // Add a week to the found control
      const value = this.getMomentFromString(last_date_value).add(1, 'week');

      (<FormArray>this.datesForm.get('dates')).push(new FormGroup({
        // Initialize the new form control with a date one week in advance
        date: new FormControl(this.getStringDateFromMoment(value), Validators.required)
      }));
    }
  }

  /**
   * Removes the date control from the formArray on the provided index
   * @param num Index of the form control
   */
  removeDate(num: number)
  {
    (<FormArray>this.datesForm.get('dates')).removeAt(num);
  }

  submitForm()
  {
    if (this.bookingID) // Edit booking
    {
      if (!this.userInfoForm.valid)
      {
        console.log('user valid', this.userInfoForm.valid);
        this.markControlsAsTouch();
        return;
      }

      this.ls.loading = true;

      const b: FleaMarketBookingModel = this.booking;
      b.price = this.ticketPriceForm.controls.unit_price.value;
      b.discount = this.ticketPriceForm.controls.price_type.value;
      b.customer = {
        id: this.booking.customer ? this.booking.customer.id : null,
        first_name: this.userInfoForm.controls.first_name.value,
        last_name: this.userInfoForm.controls.last_name.value,
        email: this.userInfoForm.controls.email.value,
        phone: this.userInfoForm.controls.phone_number.value,
        company: this.userInfoForm.controls.company.value,
        streetname: this.userInfoForm.controls.street.value,
        street_number: this.userInfoForm.controls.street_number.value,
        city: this.userInfoForm.controls.place.value,
        post_code: this.userInfoForm.controls.post_code.value,
        goods: this.userInfoForm.controls.category.value,
      };
      b.spots = this.fleaMarket.selected_places;
      b.note = this.booking_notes;

      this.fleaMarket.updateFleaMarketBooking(b).subscribe(res =>
      {
        this.ls.loading = false;
        console.log('Update flea booking ', res);
        this.helper.successNoti(this.i18n.instant('NOTI.booking_saved'));
        this.router.navigate(['/booking/details/' + res.id]);
        this.spotsChanged = false;

      }, err =>
        {
          console.log('Update booking error', err);
          this.helper.errorNoti(this.i18n.instant('NOTI.error_saving_booking'), err.error.message);
          this.ls.loading = false;
        });
    }
    else // New booking
    {
      console.log('dates valid', this.datesForm.valid);
      console.log('ticket valid', this.ticketPriceForm.valid);
      console.log('user valid', this.userInfoForm.valid);

      if (!this.areFormsValid())
      {
        this.markControlsAsTouch();
        return;
      }

      this.ls.loading = true;

      const b: FleaMarketBookingModel = {
        booking_dates: this.getBookingDates(),
        price: this.ticketPriceForm.controls.unit_price.value,
        discount: this.ticketPriceForm.controls.price_type.value,
        customer: {
          first_name: this.userInfoForm.controls.first_name.value,
          last_name: this.userInfoForm.controls.last_name.value,
          email: this.userInfoForm.controls.email.value,
          phone: this.userInfoForm.controls.phone_number.value,
          company: this.userInfoForm.controls.company.value,
          streetname: this.userInfoForm.controls.street.value,
          street_number: this.userInfoForm.controls.street_number.value,
          city: this.userInfoForm.controls.place.value,
          post_code: this.userInfoForm.controls.post_code.value,
          goods: this.userInfoForm.controls.category.value,
        },
        spots: this.fleaMarket.selected_places,
        status: STATUS_NOT_PAID
      };

      this.fleaMarket.createFleaMarketBooking(b).subscribe(res =>
      {
        this.ls.loading = false;
        console.log('Create flea booking ', res);
        this.helper.successNoti(this.i18n.instant('NOTI.booking_saved'));
        this.router.navigate(['/booking/details/' + res.id]);
        this.spotsChanged = false;

      }, err =>
        {
          console.log('Create booking error', err);
          this.helper.errorNoti(this.i18n.instant('NOTI.error_saving_booking'), err.error.message);
          this.ls.loading = false;
        });
    }
  }

  areFormsValid(): boolean
  {
    return this.datesForm.valid && this.userInfoForm.valid && this.ticketPriceForm.valid;
  }

  private markControlsAsTouch()
  {
    Object.keys(this.userInfoForm.controls).forEach(field =>
    {
      const control = this.userInfoForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    Object.keys(this.ticketPriceForm.controls).forEach(field =>
    {
      const control = this.ticketPriceForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  /**
   * Converts the date in string format to a moment object
   * @param date
   * @param format
   */
  private getMomentFromString(date: string, format = 'dddd, DD.MM.YYYY'): Moment
  {
    return moment(date, format, 'de', true);
  }

  /**
   * Converts the moment object to a string format
   * @param date
   * @param format
   */
  private getStringDateFromMoment(date: Moment, format = 'dddd, DD.MM.YYYY'): string
  {
    return moment(date).locale('de').format(format);
  }


  addUntilEndOfMonth()
  {
    // Get current value
    const date = this.getDatesControls()[this.getDatesControls().length - 1].controls['date'].value;
    // Get current date's end of month
    const date_a = this.getMomentFromString(date).endOf('month');
    // Get current date to moment object
    const date_b = this.getMomentFromString(date);
    // Get the difference in weeks to the end of the month
    const diff = date_a.diff(date_b, 'week');
    // Add the amount of dates in difference
    this.addNewDate(diff);
  }
  addUntilEndOfYear()
  {
    // Get current value
    const date = this.getDatesControls()[this.getDatesControls().length - 1].controls['date'].value;
    // Get current date's end of month
    const date_a = this.getMomentFromString(date).endOf('year');
    // Get current date to moment object
    const date_b = this.getMomentFromString(date);
    // Get the difference in weeks to the end of the month
    const diff = date_a.diff(date_b, 'week');
    // Add the amount of dates in difference
    this.addNewDate(diff);
  }
  addOneYear()
  {
    this.addNewDate(52); // Add 52 weeks
  }

  /**
   * Calculate how many weeks are left in a booking
   */
  remainingWeeks(): number
  {
    let rem = 0;

    if (this.booking)
    {
      this.booking.booking_dates.forEach(element =>
      {
        if (moment(element.date, 'YYYY-MM-DD').isAfter(moment()))
        {
          rem++;
        }
      });
    }

    return rem || 0;
  }

  getStatus(status: number)
  {
    return STATUSES.find(x => x.id === status);
  }

  /**
   * Get string array of booking dates to send to backend
   */
  private getBookingDates(): { date: string }[]
  {
    const booking_dates = [];

    this.getDatesControls().forEach(date =>
    {
      const mom_date = this.getMomentFromString(date.controls.date.value);

      booking_dates.push({ date: mom_date.format('YYYY-MM-DD') });
    });

    return booking_dates;
  }

  /**
   * Creates booking invoice
   */
  createInvoice()
  {
    if (this.booking && (this.booking.status === STATUS_PAID || this.booking.status === STATUS_STORNO))
    {
      this.fleaMarket.getFleaInvoice(this.booking.invoices[0].id).subscribe(res =>
      {
        const dialogRef = this.dialog.open(FleaInvoicePrintComponent, { data: res, disableClose: true, autoFocus: false });
        dialogRef.afterClosed().subscribe(dialogResponse =>
        {
        });
      });
    }
    else
    {
      this.ls.loading = true;
      this.fleaMarket.createFleaMarketInvoice(this.bookingID, this.topayControl.value).subscribe(res =>
      {
        console.log('Invoice response: ', res);

        this.fleaMarket.getFleaInvoice(res.id).subscribe(res2 =>
        {
          this.ls.loading = false;

          const dialogRef = this.dialog.open(FleaInvoicePrintComponent, { data: res2, disableClose: true, autoFocus: false });
          dialogRef.afterClosed().subscribe(dialogResponse =>
          {
            this.getBooking();
          });
        });
      }, err =>
      {
        console.log('Invoice error', err);
        this.ls.loading = false;
      });
    }
  }

  stornoBooking()
  {
    this.ls.loading = true;
    this.fleaMarket.stornoFleaMarketBooking(this.bookingID).subscribe(storno_res =>
    {
      console.log('Storno response: ', storno_res);

      this.fleaMarket.getFleaInvoice(storno_res.id).subscribe(res =>
      {
        this.ls.loading = false;
        const dialogRef = this.dialog.open(FleaInvoicePrintComponent, { data: res, disableClose: true, autoFocus: false });
        dialogRef.afterClosed().subscribe(dialogResponse =>
        {
          this.getBooking();
        });
      }, err =>
        {
          console.log('Invoice details error', err);
          this.helper.errorNoti(this.i18n.instant('NOTI.an_error_has_occurred'));
          this.getBooking();
        });
    }, err =>
      {
        console.log('Storno error', err);
        this.helper.errorNoti(this.i18n.instant('NOTI.an_error_has_occurred'));
        this.ls.loading = false;
      });
  }

  /**
   * Opens the change places dialog
   */
  changePlaces()
  {
    let ref;
    if (this.booking) // If booking exists send spots from booking
    {
      ref = this.dialog.open(ChangePlacesDialogComponent, {
        autoFocus: false, data: {
          spots: this.booking.spots, dates: this.getBookingDates()
        }, minWidth: 400
      });
    }
    else // If adding new booking, dont send spots data, which will select the spots from a service
    {
      ref = this.dialog.open(ChangePlacesDialogComponent, {
        autoFocus: false,
        minWidth: 400,
        data: {
          dates: this.getBookingDates()
        }
      });
    }

    // Subscribe to the dialog response and if response returns ok true,
    // set new spots to the form and to booking object if exists
    ref.afterClosed().subscribe(res =>
    {
      console.log('Dialog resp:', res);
      if (res && res.ok)
      {
        if (this.booking)
        {
          this.booking.spots = res.places;
        }
        this.ticketPriceForm.controls.place_number.setValue(res.places.map(x => x.name).toString());
        this.fleaMarket.selected_places = res.places;
        this.spotsChanged = true;
      }
    });
  }

  printPlaceNumber()
  {
    const mywindow = window.open('', '_blank');

    mywindow.document.write('<html><head><title>' + document.title + '</title><style>*{font-size:22px}</style>');
    mywindow.document.write('</head><body>');
    mywindow.document.write('<p>Platznummer(n):</p>');

    mywindow.document.write(this.fleaMarket.selected_places.map(x => x.name).toString());
    mywindow.document.write('<br>');
    mywindow.document.write('<p>Datum:</p>');
    this.getDatesControls().forEach(control =>
    {
      mywindow.document.write(`${this.helper.getMomentFromString(control.value.date).format('DD.MM.YYYY')}<br>`);
    });

    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
  }

  /**
   * Prints the invoice
   * @param invoice_id
   */
  printInvoiceById(invoice_id: number)
  {
    this.fleaMarket.getFleaInvoice(invoice_id).subscribe(res =>
    {
      const dialogRef = this.dialog.open(FleaInvoicePrintComponent, { data: res, disableClose: true, autoFocus: false });
    });
  }
}

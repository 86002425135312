import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FleaMarketService } from '../../../services/flea-market.service';
import { SPOTS } from '../../../constants/spots';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { HelperService } from '../../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-places-dialog',
  templateUrl: './change-places-dialog.component.html',
  styleUrls: ['./change-places-dialog.component.scss']
})
export class ChangePlacesDialogComponent implements OnInit, OnDestroy
{
  selected_spots: { id?: number, name: string }[];
  /**
   * All existing spots
   */
  spots: string[] = [];
  form: FormGroup;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ChangePlacesDialogComponent>,
    private fleaMarket: FleaMarketService,
    @Inject(MAT_DIALOG_DATA) public data: { spots: { id: number, name: string }[], dates: { date: string }[] },
    private fb: FormBuilder,
    private helper: HelperService,
    private i18n: TranslateService
  )
  {
    this.createForm();
  }

  ngOnInit()
  {
    this.loading = true;
    if (this.data && this.data.spots) // If data exist, that means that booking is being edited
    {
      this.selected_spots = this.data.spots;
      console.log('Spots: ', this.data.spots);
    }
    else // Else a new booking is being added, and we use selected spots from the service
    {
      this.selected_spots = this.fleaMarket.selected_places;
    }
    SPOTS.forEach(row =>
    {
      row.spots.forEach(s =>
      {
        if (s.name !== 'X')
        {
          this.spots.push(s.name);
        }
      });
    });

    this.addSpots();
  }
  ngOnDestroy(): void
  {
    this.helper.clearNotifications();
  }

  private createForm()
  {
    this.form = this.fb.group({
      spots: this.fb.array([])
    });
  }

  /**
   * Pushes the selected spots to an array of form controls
   */
  private addSpots()
  {
    this.selected_spots.forEach(s =>
    {
      (<FormArray>this.form.get('spots')).push(new FormGroup({
        spot: new FormControl(s.name)
      }));
    });

    this.loading = false;
  }

  getSpotsFormGroups(): FormGroup[]
  {
    // @ts-ignore
    return (<FormGroup[]>this.form.get('spots').controls);
  }

  save()
  {
    const places = [];
    this.getSpotsFormGroups().forEach((group, i) =>
    {
      places.push({ id: this.selected_spots[i].id, name: group.controls.spot.value });
    });

    console.log('Places: ', places);
    console.log('Dates: ', this.data.dates.map(d => d.date));

    this.fleaMarket.checkSpotAvailability(places, this.data.dates.map(d => d.date)).subscribe(res =>
    {
      console.log('Response', res);
      if (res.available)
      {
        this.dialogRef.close({ ok: true, places });
      }
      else
      {
        this.helper.errorNoti(this.i18n.instant('NOTI.spots_taken'));
      }
    });
  }
}

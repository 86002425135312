import { Component, OnInit } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Price } from '../../constants/price';
import { PriceService, PriceModel } from '../../services/price.service';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-price-adjust',
  templateUrl: './price-adjust.component.html',
  styleUrls: ['./price-adjust.component.scss']
})
export class PriceAdjustComponent implements OnInit
{
  form: FormGroup;
  loading = false;

  prices: PriceModel[] = [];

  constructor(
    public nav: NavService,
    private fb: FormBuilder,
    private priceService: PriceService,
    private helper: HelperService,
    private i18n: TranslateService
  )
  {}

  ngOnInit()
  {
    this.loading = true;
    this.priceService.getPrices().subscribe(res =>
    {
      console.log('Prices', res);
      this.priceService.setPrices(res);
      this.prices = res;

      this.createForm();
      this.loading = false;

    }, err =>
    {
      console.log('Error getting prices', err);
      this.helper.errorNoti(this.i18n.instant('ERR.error_getting_prices'));
    });
  }

  private createForm()
  {
    const regex = new  RegExp(/^[1-9][0-9]*$/);

    this.form = this.fb.group({
      flea_market_ticket_price: [Price.FLEA, Validators.compose([Validators.required, Validators.pattern(regex)])],
      flea_market_discount_ticket_price: [Price.FLEA_DISCOUNT, Validators.compose([Validators.required, Validators.pattern(regex)])],

      car_market_ticket_price: [Price.CAR, Validators.compose([Validators.required, Validators.pattern(regex)])],
      car_market_discount_ticket_price: [Price.CAR_DISCOUNT, Validators.compose([Validators.required, Validators.pattern(regex)])]
    });
  }

  getControl(field: string): FormControl
  {
    return this.form.controls[field] as FormControl;
  }

  savePrice()
  {
    if (this.form.invalid)
    {
      return;
    }

    const price_model: PriceModel[] = [
      {
        id: this.prices.find(p => p.type === 'flea').id || 4,
        type: 'flea',
        price: this.getControl('flea_market_ticket_price').value,
        discount_price: this.getControl('flea_market_discount_ticket_price').value
      },
      {
        id: this.prices.find(p => p.type === 'car').id || 5,
        type: 'car',
        price: this.getControl('car_market_ticket_price').value,
        discount_price: this.getControl('car_market_discount_ticket_price').value
      },
      {
        id: this.prices.find(p => p.type === 'kino').id || 6,
        type: 'kino',
        price: Price.KINO,
        discount_price: Price.KINO_DISCOUNT
      }
    ];

    console.log('Price model: ', price_model);

    this.priceService.changePrices(price_model).subscribe(res =>
    {
      console.log('Change price: ', res);
      this.priceService.setPrices(price_model);
      this.helper.successNoti(this.i18n.instant('NOTI.prices_changed'));

    }, err =>
    {
      console.log('Error changing prices', err);
      this.helper.errorNoti(this.i18n.instant('ERR.error_changing_prices'));
    });
  }

}

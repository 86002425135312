export const CATEGORIES = [
  { id: 1, name: 'Neuware', dicounted: false, isGroup: true },
  { id: 2, name: 'diverser Hausrat', dicounted: false },
  { id: 3, name: 'Antiquitäten', dicounted: false },
  { id: 4, name: 'Uhren', dicounted: false },
  { id: 5, name: 'Handys, Elektronik, elektronische Spiele', dicounted: false },
  { id: 6, name: 'Textil', dicounted: false },
  { id: 7, name: 'Haushalt', dicounted: false },
  { id: 8, name: 'Kosmetik', dicounted: false },
  { id: 9, name: 'Werkzeug', dicounted: false },
  { id: 10, name: 'Spielzeug', dicounted: false },
  { id: 11, name: 'Sport', dicounted: false },
  { id: 12, name: 'Automotiv', dicounted: false },


  { id: 13, name: 'Gebrauchtware', dicounted: true, isGroup: true },
  { id: 14, name: 'diverser Hausrat', dicounted: true },
  { id: 15, name: 'Antiquitäten', dicounted: true },
  { id: 16, name: 'Uhren', dicounted: true },
  { id: 17, name: 'Handys, Elektronik, elektronische Spiele', dicounted: true },
  { id: 18, name: 'Textil', dicounted: true },
  { id: 19, name: 'Haushalt', dicounted: true },
  { id: 20, name: 'Kosmetik', dicounted: true },
  { id: 21, name: 'Werkzeug', dicounted: true },
  { id: 22, name: 'Spielzeug', dicounted: true },
  { id: 23, name: 'Sport', dicounted: true },
  { id: 24, name: 'Automotiv', dicounted: true },
];

/* export const CATEGORIES = [
  {
    group_name: 'Neuware',
    items: [
      { id: 1, name: 'diverser Hausrat' },
      { id: 2, name: 'Antiquitäten' },
      { id: 3, name: 'Uhren' },
      { id: 4, name: 'Handys, Elektronik, elektronische Spiele' },
      { id: 5, name: 'Textil' },
      { id: 6, name: 'Haushalt' },
      { id: 7, name: 'Kosmetik' },
      { id: 8, name: 'Werkzeug' },
      { id: 9, name: 'Spielzeug' },
      { id: 10, name: 'Sport' },
      { id: 11, name: 'Automotiv' }
    ]
  },
  {
    group_name: 'Gebrauchtware',
    items: [
      { id: 12, name: 'diverser Hausrat' },
      { id: 13, name: 'Antiquitäten' },
      { id: 14, name: 'Uhren' },
      { id: 15, name: 'Handys, Elektronik, elektronische Spiele' },
      { id: 16, name: 'Textil' },
      { id: 17, name: 'Haushalt' },
      { id: 18, name: 'Kosmetik' },
      { id: 19, name: 'Werkzeug' },
      { id: 20, name: 'Spielzeug' },
      { id: 21, name: 'Sport' },
      { id: 22, name: 'Automotiv' }
    ]
  }
]; */

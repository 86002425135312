import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Moment } from 'moment';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class HelperService
{

  constructor(
    private notify: NotificationsService
  )
  {
    moment.locale('de');
  }

  clearNotifications()
  {
    this.notify.remove();
  }

  /**
   * Creates a succes notification
   * @param title Title of the notification
   * @param content Content of the notification
   * @param override Settings to override default values
   */
  successNoti(title: string, content?: string, override?)
  {
    this.notify.success(title, content, override);
  }

  /**
   * Creates an error notification
   * @param title Title of the notification
   * @param content Content of the notification
   * @param override Settings to override default values
   */
  errorNoti(title: string, content?: string, override?)
  {
    this.notify.error(title, content, override);
  }

  /**
   * Creates a warning notification
   * @param title Title of the notification
   * @param content Content of the notification
   * @param override Settings to override default values
   */
  warningNoti(title: string, content?: string, override?)
  {
    this.notify.warn(title, content, override);
  }

  /**
   * Creates a informational notification
   * @param title Title of the notification
   * @param content Content of the notification
   * @param override Settings to override default values
   */
  infoNoti(title: string, content?: string, override?)
  {
    this.notify.info(title, content, override);
  }

  /**
   * Converts a date to backend format
   * @param date
   * @returns string in YYYY-MM-DD format
   */
  convertDateToBackendFormat(date: Moment): string
  {
    return moment(date).format('YYYY-MM-DD');
  }

  /**
   * Converts the date in string format to a moment object
   * @param date
   * @param format dddd, DD.MM.YYYY
   */
  getMomentFromString(date: string, format = 'dddd, DD.MM.YYYY'): Moment
  {
    return moment(date, format, 'de', true);
  }

  /**
   * Converts the moment object to a string format
   * @param date
   * @param format dddd, DD.MM.YYYY
   */
  getStringDateFromMoment(date: Moment, format = 'dddd, DD.MM.YYYY'): string
  {
    return moment(date).locale('de').format(format);
  }

  /**
   * Get string array of booking dates to send to backend
   *
   * @param formGroupArray
   */
  getBookingDates(formGroupArray: FormGroup[]): { date: string }[]
  {
    const booking_dates = [];

    formGroupArray.forEach(date =>
    {
      const mom_date = this.getMomentFromString(date.controls.date.value);

      booking_dates.push({ date: mom_date.format('YYYY-MM-DD') });
    });

    return booking_dates;
  }

  /**
   * Returns a base64 image from provided url
   * @param imageUrl
   * @returns Promise
   */
  async getBase64ImageFromUrl(imageUrl)
  {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) =>
    {
      const reader = new FileReader();
      reader.addEventListener('load', function ()
      {
        resolve(reader.result);
      }, false);

      reader.onerror = () =>
      {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  /**
   * Get ticket price based on total amount of price and number of spots and weeks reserved
   *
   * example: 10€ total for 2 spots and 1 week = 5€ per ticket
   *
   * @param total
   * @param totalSpots
   * @param totalWeeks
   */
  getTicketPriceBasedOnTotal(total: number, totalSpots: number, totalWeeks: number): number
  {
    return Number.parseFloat((total / (totalSpots * totalWeeks)).toFixed(2));
  }
}

import { Injectable } from '@angular/core';
import { ApiDefinition } from '../constants/api.def';
import { HttpClient } from '@angular/common/http';
import { FleaMarketTableResponseModel, FleaMarketBookingResponseModel, FleaMarketInvoiceResponseModel, FleaMarketBookingModel, SpotModel, QuickFleaMarketBookingModel, FleaSpot } from '../models/flea-market.model';
import { Observable } from 'rxjs';
import { WirecardModelResponse } from '../models/wirecard.model';
import { InvoiceResponse } from '../models/invoice.model';

@Injectable({
  providedIn: 'root'
})
export class FleaMarketService
{
  api: ApiDefinition = new ApiDefinition();
  selected_places: { id?: number; name: string; }[];

  constructor(
    private http: HttpClient
  )
  {}

  /**
   * Get flea market table data
   * /booking/flea
   * @param fromDate Start date YYYY-MM-DD format
   * @param toDate End date YYYY-MM-DD format
   */
  getTableData(fromDate: string, toDate: string): Observable<FleaMarketTableResponseModel>
  {
    const params = { fromDate, toDate };
    return this.http.get<FleaMarketTableResponseModel>(this.api.flea_booking + '/table', { params });
  }

  /**
   * GET /booking/flea/pdf
   * gets a pdf file
   * @param fromDate Start date YYYY-MM-DD format
   * @param toDate End date YYYY-MM-DD format
   * @returns pdf file
   */
  getPDF(fromDate: string, toDate: string)
  {
    const params = { fromDate, toDate };
    return this.http.get(this.api.flea_booking + '/pdf', { params, responseType: 'blob' });
  }

  /**
   * GET /booking/flea/excel
   * gets a excel file
   * @param fromDate Start date YYYY-MM-DD format
   * @param toDate End date YYYY-MM-DD format
   * @returns excel file
   */
  getExcel(fromDate: string, toDate: string)
  {
    const params = { fromDate, toDate };
    return this.http.get(this.api.flea_booking + '/excel', { params, responseType: 'blob' });
  }

  /**
   * Get booking details
   *
   * GET /booking/flea/{id}/details
   * @param id
   */
  getBookingByID(id: number): Observable<FleaMarketBookingResponseModel>
  {
    return this.http.get<FleaMarketBookingResponseModel>(this.api.flea_booking + `/${id}/details`);
  }

  /**
   * Create a new new invoice for FleaMarketBooking Booking
   * @param id Booking id
   * @param amount_given
   */
  createFleaMarketInvoice(id: number, amount_given: number): Observable<FleaMarketInvoiceResponseModel>
  {
    return this.http.post<FleaMarketInvoiceResponseModel>(this.api.flea_booking + `/${id}/invoice`, { amount_given });
  }

  /**
   * Storno a FleaMarketBooking Booking and create storno invoice
   * @param id Booking id
   */
  stornoFleaMarketBooking(id: number): Observable<FleaMarketInvoiceResponseModel>
  {
    return this.http.post<FleaMarketInvoiceResponseModel>(this.api.flea_booking + `/${id}/storno`, {});
  }

  /**
   * Create a new Fleamarket Booking
   * @param booking
   */
  createFleaMarketBooking(booking: FleaMarketBookingModel): Observable<FleaMarketBookingResponseModel>
  {
    return this.http.post<FleaMarketBookingResponseModel>(this.api.flea_booking, booking);
  }

  /**
   * Create quick Booking
   * @param body
   */
  createFleaMarketQuickBooking(booking: QuickFleaMarketBookingModel): Observable<FleaMarketBookingResponseModel>
  {
    return this.http.post<FleaMarketBookingResponseModel>(this.api.flea_booking, booking);
  }

  /**
   * Create quick reservation
   * @param body
   */
  createFleaMarketQuickReservation(booking: QuickFleaMarketBookingModel): Observable<FleaMarketBookingResponseModel>
  {
    booking.discount = false;
    return this.http.post<FleaMarketBookingResponseModel>(this.api.flea_booking, booking);
  }

  /**
   * Update Fleamarket Booking
   * @param booking
   */
  updateFleaMarketBooking(booking: FleaMarketBookingModel): Observable<FleaMarketBookingResponseModel>
  {
    return this.http.put<FleaMarketBookingResponseModel>(this.api.flea_booking, booking);
  }

  /**
   * Check if spots are available for given dates
   * @param spots
   * @param date Format YYYY-MM-DD
   */
  checkSpotAvailability(spots: SpotModel[], dates: string[]): Observable<{ available: boolean }>
  {
    return this.http.post<{available: boolean}>(this.api.flea_booking + '/spots/available',
      { spot_names: spots, booking_dates: dates });
  }

  /**
   * Gets the spots for a given date and their statuses
   * @param date Format YYYY-MM-DD
   */
  getSpotsForDate(date: string): Observable<FleaSpot[]>
  {
    return this.http.get<FleaSpot[]>(this.api.flea_booking + `/${date}/spots`);
  }

  /**
   * Gets the invoice details
   * @param invoice_id
   */
  getFleaInvoice(invoice_id: number): Observable<InvoiceResponse>
  {
    return this.http.get<InvoiceResponse>(this.api.invoice + `/${invoice_id}/details`);
  }

  /**
   * Deletes flea market booking
   *
   * /booking/flea/{id}/booking
   * @param booking_id
   */
  deleteBooking(booking_id: number)
  {
    return this.http.delete(this.api.flea_booking + `/${booking_id}/booking`);
  }

  /**
   * Removes the selected spots from the informal booking
   * @param spots
   */
  removeInformalSpot(spots: { name: string; booking_date: string }[])
  {
    return this.http.put(this.api.flea_booking + '/informal', { spots: spots });
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '../../../../node_modules/@angular/material';

@Component({
  selector: 'app-changes-not-saved-dialog',
  templateUrl: './changes-not-saved-dialog.component.html',
  styleUrls: ['./changes-not-saved-dialog.component.scss']
})
export class ChangesNotSavedDialogComponent implements OnInit
{

  constructor(
    public dialogRef: MatDialogRef<ChangesNotSavedDialogComponent>
  ) { }

  ngOnInit()
  {
  }

}

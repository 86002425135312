export const STATUS_RESERVED: number = 0;
export const STATUS_NOT_PAID: number = 1;
export const STATUS_PARTIALLY_PAID: number = 2;
export const STATUS_PAID: number = 3;
export const STATUS_INFORMAL_RESERVATION: number = 4;
export const STATUS_STORNO: number = 5;
export const STATUS_PENDING: number = 6;
export const STATUS_FREE: number = 7;
export const STATUS_ALL: number = -1;

export const STATUSES = [
  { id: STATUS_PAID, name: 'STATUS.paid', color: 'green' },
  { id: STATUS_PARTIALLY_PAID, name: 'STATUS.partially_paid', color: 'orange' },
  { id: STATUS_NOT_PAID, name: 'STATUS.not_paid', color: 'red' },
  { id: STATUS_STORNO, name: 'STATUS.cancellation', color: 'black' },
  { id: STATUS_INFORMAL_RESERVATION, name: 'STATUS.informal_reservation', color: '#cc6699' },
  { id: STATUS_RESERVED, name: 'STATUS.reserved', color: 'darkgray' },
  { id: STATUS_FREE, name: 'STATUS.free', color: 'yellow' }
];

/**
 * Class that holds the ticket price values
 */
export class Price
{
  static FLEA: number = 37;
  static FLEA_DISCOUNT: number = 27;

  static CAR: number = 25;
  static CAR_DISCOUNT: number = 15;

  static KINO: number = 8;
  static KINO_DISCOUNT: number = 5;
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

import * as moment from 'moment';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KinoBookingService } from '../../services/kino-booking.service';
import { STATUS_NOT_PAID } from '../../constants/statuses';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingService } from '../../services/loading.service';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Price } from '../../constants/price';
import { MatDialog } from '@angular/material/dialog';
import { KinoInovicePrintComponent } from '../kino-inovice-print/kino-inovice-print.component';

@Component({
  selector: 'app-kino-booking',
  templateUrl: './kino-booking.component.html',
  styleUrls: ['./kino-booking.component.scss']
})
export class KinoBookingComponent implements OnInit, OnDestroy
{
  form: FormGroup;
  total: number;

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private kinoBookingService: KinoBookingService,
    private ls: LoadingService,
    private helper: HelperService,
    private i18n: TranslateService,
    private dialog: MatDialog
  )
  {
    moment.locale('de');
    this.createForm();
  }

  ngOnInit()
  {
  }
  ngOnDestroy(): void
  {
  }

  private createForm()
  {
    this.form = this.fb.group({
      date: [moment(), Validators.required],
      unit_price: [Price.KINO, Validators.required],
      tickets: [1, Validators.required]
    });

    this.calcTotal();

    this.form.controls.unit_price.valueChanges.subscribe((value) =>
    {
      if (value < 0) { this.form.controls.unit_price.setValue(0); }
      this.calcTotal();
    });
    this.form.controls.tickets.valueChanges.subscribe((value) =>
    {
      if (value < 1) { this.form.controls.tickets.setValue(1); }
      this.calcTotal();
    });
  }

  /**
   * Calculates the total price
   */
  private calcTotal()
  {
    this.total = this.form.controls.unit_price.value * this.form.controls.tickets.value;
  }

  goBack()
  {
    this.location.back();
  }

  private getControl(name: string): FormControl
  {
    return this.form.controls[name] as FormControl;
  }

  save()
  {
    this.ls.loading = true;
    this.kinoBookingService.createNewKinoBooking({
      booking_dates: [{ date: this.getControl('date').value.format('YYYY-MM-DD') }],
      amount: this.getControl('tickets').value,
      price: this.getControl('unit_price').value,
      status: STATUS_NOT_PAID,
      discount: false
    }).subscribe(res_booking =>
    {
      console.log('Booking response', res_booking);

      this.kinoBookingService.createKinoInvoice(res_booking.id, (res_booking.price * res_booking.amount)).subscribe(res_invoice =>
      {
        this.ls.loading = false;
        console.log('Invoice response', res_invoice);

        const dialogRef = this.dialog.open(KinoInovicePrintComponent, { data: res_invoice, disableClose: true, autoFocus: false });
        dialogRef.afterClosed().subscribe(dialogResponse =>
        {
          this.helper.successNoti(this.i18n.instant('NOTI.booking_saved'));
          this.location.back();
        });

      }, (err: HttpErrorResponse) =>
        {
          this.ls.loading = false;
          console.log(err);
        });

    }, (err: HttpErrorResponse) =>
      {
        this.ls.loading = false;
        console.log(err);
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { Router, NavigationStart, RouterEvent } from '@angular/router';
import { LoadingService } from './services/loading.service';
import { AuthService } from './services/auth.service';
import { PriceService } from './services/price.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{
  langs: string[];
  langControl: FormControl;

  constructor(
    public translate: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private router: Router,
    public loading: LoadingService,
    public auth: AuthService,
    private priceService: PriceService
  )
  {
    translate.addLangs(['en', 'de']); // Add languages to the service
    translate.setDefaultLang('de'); // Set default language

    this.langs = translate.getLangs(); // Get currently loaded languages for select list

    const pref_lang = this.getStoredLang();
    this.langControl = new FormControl(pref_lang ? pref_lang : translate.defaultLang);
    translate.use(pref_lang ? pref_lang : translate.defaultLang);
    this.dateAdapter.setLocale(pref_lang ? pref_lang : translate.defaultLang);
  }

  ngOnInit()
  {
    this.subscribeToRouterEvent();
    this.priceService.getPrices().subscribe(res =>
    {
      this.priceService.setPrices(res);
    }, err =>
    {
      console.log('Error getting prices');
      this.auth.logout();
    });
  }

  changeLang(event: MatSelectChange)
  {
    this.translate.use(event.value);
    localStorage.setItem('pref_lang', event.value);
    this.dateAdapter.setLocale(event.value);
  }

  /**
   * Get the language stored in local storage
   */
  private getStoredLang(): string | null | undefined
  {
    return localStorage.getItem('pref_lang');
  }

  /**
   * Enables the loading screen on router navigation
   */
  subscribeToRouterEvent()
  {
    this.router.events.subscribe((event) =>
    {

      if (event instanceof NavigationStart)
      {
        this.loading.loading = true;
      }
      else
      {
        this.loading.loading = false;
      }
    });
  }
}

import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { FormControl, Validators } from '@angular/forms';

import * as moment from 'moment';
import { STATUSES, STATUS_PAID, STATUS_STORNO, STATUS_ALL } from '../../constants/statuses';
import { ORIGINS } from '../../constants/origins';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { isArray } from 'util';
import { KinoTableResponseModel, KinoTableRowModel } from '../../models/kino-booking.model';
import { KinoBookingService } from '../../services/kino-booking.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DownloadFileService } from '../../services/download-file.service';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { KinoInovicePrintComponent } from '../kino-inovice-print/kino-inovice-print.component';

@Component({
  selector: 'app-kino',
  templateUrl: './kino.component.html',
  styleUrls: ['./kino.component.scss']
})
export class KinoComponent implements OnInit, OnDestroy
{
  loading = false;
  stornoLoadingID = [];
  invoiceLoadingID = [];

  date_from_Control: FormControl;
  date_to_Control: FormControl;
  status_Control: FormControl;
  origin_Control: FormControl;

  // Only keep "paid" and "cancel" options
  statuses = STATUSES.filter(s => s.id === STATUS_PAID || s.id === STATUS_STORNO);
  origins = ORIGINS.filter(o => o.id === 0 || o.id === 2); // Only keep "all" and "local" options

  // Table
  dataSource = new MatTableDataSource<KinoTableRowModel>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayedColumns = ['id', 'date', 'status', 'total', 'amount', 'edit'];

  data: KinoTableResponseModel;
  tableData: KinoTableRowModel[] = [];

  constructor(
    public nav: NavService,
    private kinoService: KinoBookingService,
    private downloadService: DownloadFileService,
    private helper: HelperService,
    private i18n: TranslateService,
    private dialog: MatDialog
  )
  {
    moment.locale('de');
  }

  ngOnInit()
  {
    this.createFormControls();
  }
  ngOnDestroy(): void
  {
    this.helper.clearNotifications();
  }

  private createFormControls()
  {
    this.date_from_Control = new FormControl(moment().startOf('week'));
    this.date_to_Control = new FormControl(moment().endOf('week'));

    this.status_Control = new FormControl([-1], [Validators.required]);
    this.origin_Control = new FormControl(this.origins[0].id, [Validators.required]);

    this.getData();
  }

  /**
   * Resets the filters
   */
  resetFilters()
  {
    this.createFormControls();
  }

  fillSource()
  {
    this.dataSource.data = this.tableData;
  }

  getData()
  {
    this.loading = true;
    this.kinoService.getTableData(this.date_from_Control.value.format('YYYY-MM-DD'), this.date_to_Control.value.format('YYYY-MM-DD'))
      .subscribe((res) =>
      {
        console.log('Kino table:', res);
        this.data = res;
        this.tableData = res.data;
        this.fillSource();
        this.loading = false;
        setTimeout(() =>
        {
          this.dataSource.sort = this.sort;
        }, 0);

      }, (err: HttpErrorResponse) =>
        {
          this.helper.infoNoti(this.i18n.instant('NOTI.no_booking_in_date_range'));
          this.tableData = [];
          this.fillSource();

          this.loading = false;
          console.log(err);
        });
  }

  /**
   * Results filter for table
   */
  filterResults()
  {
    let selectedValue: number[] = this.status_Control.value;

    if (selectedValue.length >= 1)
    {
      if (selectedValue.includes(-1))
      {
        // If All is selected, filter it out
        this.status_Control.setValue(selectedValue.filter(x => x !== -1));
        selectedValue = this.status_Control.value;
      }

      console.log('Selected', selectedValue);
      console.log('Table data:', this.tableData);
      this.dataSource.data = this.tableData.filter(row => selectedValue.includes(row.status));
    }
    else if (selectedValue.length < 1 && !selectedValue.includes(-1))
    {
      this.status_Control.setValue([-1]);
      this.dataSource.data = this.tableData;
    }
  }
  resetStatusSelection()
  {
    this.status_Control.setValue([-1]);
    this.dataSource.data = this.tableData;
  }


  pdf()
  {
    this.kinoService.getPDF(this.date_from_Control.value.format('YYYY-MM-DD'), this.date_to_Control.value.format('YYYY-MM-DD'))
      .subscribe((res: Blob) =>
      {
        const title = 'Kino report ' + this.date_from_Control.value.format('YYYY-MM-DD') + ' ' + this.date_to_Control.value.format('YYYY-MM-DD');
        this.downloadService.download(res, title, '.pdf');

      }, (err: HttpErrorResponse) =>
        {
          console.log(err);
        });
  }
  excel()
  {
    this.kinoService.getExcel(this.date_from_Control.value.format('YYYY-MM-DD'), this.date_to_Control.value.format('YYYY-MM-DD'))
      .subscribe((res: any) =>
      {
        const title = 'Kino report ' + this.date_from_Control.value.format('YYYY-MM-DD') + ' ' + this.date_to_Control.value.format('YYYY-MM-DD');
        this.downloadService.download(res, title, '.xlsx');

      }, (err: HttpErrorResponse) =>
        {
          console.log(err);
        });
  }
  /**
   * print table with printer
   */
  print()
  {
    window.print();
  }

  /**
   * Create a new new invoice for Kino Booking
   */
  printReceipt(row: KinoTableRowModel)
  {
    this.invoiceLoadingID.push(row.id);

    if (row.status === STATUS_PAID || row.status === STATUS_STORNO)
    {
      this.kinoService.getKinoInvoice(row.invoice[0]).subscribe(res =>
      {
        const dialogRef = this.dialog.open(KinoInovicePrintComponent, { data: res, disableClose: true, autoFocus: false });
        dialogRef.afterClosed().subscribe(dialogResponse =>
        {
          this.invoiceLoadingID = this.invoiceLoadingID.filter(x => x !== row.id);
        });
      });
    }
    else
    {
      this.kinoService.createKinoInvoice(row.id, row.total).subscribe(res =>
      {
        const dialogRef = this.dialog.open(KinoInovicePrintComponent, { data: res, disableClose: true, autoFocus: false });
        dialogRef.afterClosed().subscribe(dialogResponse =>
        {
          this.invoiceLoadingID = this.invoiceLoadingID.filter(x => x !== row.id);
          console.log('Invoice res', res);
          this.getData();
        });
      });
    }

  }
  /**
   * Storno a Kino Booking and create storno invoice
   */
  storno(row: KinoTableRowModel)
  {
    this.stornoLoadingID.push(row.id);
    this.kinoService.stornoKinoBooking(row.id).subscribe(res =>
    {
      const dialogRef = this.dialog.open(KinoInovicePrintComponent, { data: res, disableClose: true, autoFocus: false });
      dialogRef.afterClosed().subscribe(dialogResponse =>
      {
        this.stornoLoadingID = this.stornoLoadingID.filter(x => x !== row.id);
        console.log('Storno res', res);
        this.getData();
      });

    });
  }

  getStatusColor(element: KinoTableRowModel): string
  {
    const found = STATUSES.find(x => x.id === element.status);
    return found ? found.color : '';
  }
  getStatusTooltip(element: KinoTableRowModel): string
  {
    const found = STATUSES.find(x => x.id === element.status);
    return found ? found.name : '';
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate
{
  redirectURL;

  constructor(
    private router: Router,
    private auth: AuthService
  )
  { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
  {
    if (this.auth.isLoggedIn())
    {
      return true;
    }
    else
    {
      this.redirectURL = state.url;
      this.router.navigate(['/login']);
      return false;
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { ScheduleViewComponent } from './components/schedule-view/schedule-view.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TableViewComponent } from './components/table-view/table-view.component';
import { CarMarketComponent } from './components/car-market/car-market.component';
import { KinoComponent } from './components/kino/kino.component';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { KinoBookingComponent } from './components/kino-booking/kino-booking.component';
import { CarMarketBookingComponent } from './components/car-market-booking/car-market-booking.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { BookingDetailsComponent } from './components/schedule-view/booking-details/booking-details.component';
import { ChangePlacesDialogComponent } from './components/schedule-view/change-places-dialog/change-places-dialog.component';
import { QuickBookingDialogComponent } from './components/schedule-view/quick-booking-dialog/quick-booking-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { InformalReservationDialogComponent } from './components/schedule-view/informal-reservation-dialog/informal-reservation-dialog.component';
import { PriceAdjustComponent } from './components/price-adjust/price-adjust.component';
import { KinoInovicePrintComponent } from './components/kino-inovice-print/kino-inovice-print.component';
import { CarInvoicePrintComponent } from './components/car-invoice-print/car-invoice-print.component';
import { FleaInvoicePrintComponent } from './components/flea-invoice-print/flea-invoice-print.component';
import { BookingDetailsDialogComponent } from './components/schedule-view/booking-details-dialog/booking-details-dialog.component';
import { ChangeBookingTypeDialogComponent } from './components/schedule-view/change-booking-type-dialog/change-booking-type-dialog.component';
import { ChangesNotSavedDialogComponent } from './components/changes-not-saved-dialog/changes-not-saved-dialog.component';
import { EventLogComponent } from './components/event-log/event-log.component';

const routes: Routes = [
  { path: 'schedule', component: ScheduleViewComponent, canActivate: [AuthGuard] },
  { path: 'table-view', component: TableViewComponent, canActivate: [AuthGuard] },
  { path: 'booking/add', component: BookingDetailsComponent, canActivate: [AuthGuard] },
  { path: 'booking/details/:id', component: BookingDetailsComponent, canActivate: [AuthGuard] },
  { path: 'car-market', component: CarMarketComponent, canActivate: [AuthGuard] },
  { path: 'car-market/booking', component: CarMarketBookingComponent, canActivate: [AuthGuard] },
  { path: 'car-market/booking/:id', component: CarMarketBookingComponent, canActivate: [AuthGuard] },
  { path: 'kino', component: KinoComponent, canActivate: [AuthGuard] },
  { path: 'kino/booking', component: KinoBookingComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },

  { path: 'price/tickets', component: PriceAdjustComponent, canActivate: [AuthGuard] },

  { path: 'external', loadChildren: () => import('./components/external/external.module').then(m => m.ExternalModule) },
  { path: 'sqldump', loadChildren: () => import('./components/sqldump/sqldump.module').then(m => m.SqldumpModule) },

  { path: '**', redirectTo: '/login' },
];

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient)
{
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ScheduleViewComponent,
    TableViewComponent,
    CarMarketComponent,
    KinoComponent,
    KinoBookingComponent,
    CarMarketBookingComponent,
    BookingDetailsComponent,
    ChangePlacesDialogComponent,
    QuickBookingDialogComponent,
    LoginComponent,
    InformalReservationDialogComponent,
    PriceAdjustComponent,
    KinoInovicePrintComponent,
    CarInvoicePrintComponent,
    FleaInvoicePrintComponent,
    BookingDetailsDialogComponent,
    ChangeBookingTypeDialogComponent,
    ChangesNotSavedDialogComponent,
    EventLogComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SimpleNotificationsModule.forRoot({
      position: ['top', 'right'],
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true
    })
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ChangePlacesDialogComponent,
    QuickBookingDialogComponent,
    InformalReservationDialogComponent,
    KinoInovicePrintComponent,
    CarInvoicePrintComponent,
    FleaInvoicePrintComponent,
    BookingDetailsDialogComponent,
    ChangeBookingTypeDialogComponent,
    ChangesNotSavedDialogComponent
  ]
})
export class AppModule
{ }

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../../node_modules/@angular/material';
import { FleaMarketBookingResponseModel, FleaMarketBookingModel } from '../../../models/flea-market.model';
import * as moment from 'moment';
import { FleaMarketService } from '../../../services/flea-market.service';
import { HelperService } from '../../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-booking-details-dialog',
  templateUrl: './booking-details-dialog.component.html',
  styleUrls: ['./booking-details-dialog.component.scss']
})
export class BookingDetailsDialogComponent implements OnInit
{
  currentSpots: string[];
  currentDates: string[];

  note: string = '';

  constructor(
    public dialogRef: MatDialogRef<BookingDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FleaMarketBookingResponseModel,
    private fleaMarket: FleaMarketService,
    private helper: HelperService,
    private i18n: TranslateService
  )
  {
    moment().locale('de');
  }

  ngOnInit()
  {
    console.log('Booking data', this.data);
    this.currentSpots = this.data.spots.map(s => s.name);
    this.currentDates = this.data.booking_dates.map(date => moment(date.date).format('DD.MM.YYYY.'));
    this.note = this.data.note;
  }

  printPlaceNumber()
  {
    const mywindow = window.open('', '_blank');

    mywindow.document.write('<html><head><title>' + document.title + '</title><style>*{font-size:22px;font-family:Arial}</style>');
    mywindow.document.write('</head><body>');
    mywindow.document.write('<p>Platznummer(n):</p>');

    mywindow.document.write(this.data.spots.map(x => x.name).toString());
    mywindow.document.write('<br>');
    mywindow.document.write('<p>Datum:</p>');
    this.data.booking_dates.forEach(date =>
    {
      mywindow.document.write(`${moment(date.date).format('DD.MM.YYYY')}<br>`);
    });

    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
  }

  saveNotes()
  {
    const booking: FleaMarketBookingModel = this.data;
    booking.note = this.note;

    this.fleaMarket.updateFleaMarketBooking(booking).subscribe(res =>
    {
      console.log('Booking res', res);
      this.helper.successNoti(this.i18n.instant('NOTI.note_saved'));

    }, err => console.log('Booking error', err));
  }
}

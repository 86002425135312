import { Injectable } from '@angular/core';
import { ApiDefinition } from '../constants/api.def';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { KinoBookingModel, KinoBookingResponse, KinoTableResponseModel, KinoBookingInvoiceResponse } from '../models/kino-booking.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KinoBookingService
{
  api: ApiDefinition = new ApiDefinition();

  constructor(
    private http: HttpClient
  )
  { }

  /**
   * Update kino booking
   * PUT /booking/kino
   * @param body
   */
  updateKinoBooking(body: KinoBookingModel): Observable<KinoBookingResponse>
  {
    return this.http.put<KinoBookingResponse>(this.api.kino_booking, body);
  }

  /**
   * Create new kino booking
   * POST /booking/kino
   * @param body
   */
  createNewKinoBooking(body: KinoBookingModel): Observable<KinoBookingResponse>
  {
    return this.http.post<KinoBookingResponse>(this.api.kino_booking, body);
  }

  /**
   * Get booking details
   * GET /booking/kino/{id}/details
   * @param id date id
   */
  getBookingDetails(id: number): Observable<KinoBookingResponse>
  {
    return this.http.get<KinoBookingResponse>(this.api.kino_booking + `/${id}/details`);
  }

  /**
   * Get kino table data
   * /booking/kino
   * @param fromDate Start date YYYY-MM-DD format
   * @param toDate End date YYYY-MM-DD format
   */
  getTableData(fromDate: string, toDate: string): Observable<KinoTableResponseModel>
  {
    const params = { fromDate, toDate };
    return this.http.get<KinoTableResponseModel>(this.api.kino_booking + '/table', { params });
  }

  /**
   * Create a new new invoice for Kino Booking
   * /booking/kino/{id}/invoice
   * @param id booking id
   * @param amount_given
   */
  createKinoInvoice(id: number, amount_given: number): Observable<KinoBookingInvoiceResponse>
  {
    return this.http.post<KinoBookingInvoiceResponse>(this.api.kino_booking + `/${id}/invoice`, { amount_given });
  }

  /**
   * Storno a Kino Booking and create storno invoice
   * /booking/kino/{id}/storno
   * @param id booking id
   */
  stornoKinoBooking(id: number): Observable<KinoBookingInvoiceResponse>
  {
    return this.http.post<KinoBookingInvoiceResponse>(this.api.kino_booking + `/${id}/storno`, null);
  }

  /**
   * GET /booking/kino/pdf
   * gets a pdf file
   * @param fromDate Start date YYYY-MM-DD format
   * @param toDate End date YYYY-MM-DD format
   * @returns pdf file
   */
  getPDF(fromDate: string, toDate: string)
  {
    const params = { fromDate, toDate };
    return this.http.get(this.api.kino_booking + '/pdf', { params, responseType: 'blob' });
  }

  /**
   * GET /booking/kino/excel
   * gets a excel file
   * @param fromDate Start date YYYY-MM-DD format
   * @param toDate End date YYYY-MM-DD format
   * @returns excel file
   */
  getExcel(fromDate: string, toDate: string)
  {
    const params = { fromDate, toDate };
    return this.http.get(this.api.kino_booking + '/excel', { params, responseType: 'blob' });
  }

  /**
   * Get the invoice details
   * @param invoice_id
   */
  getKinoInvoice(invoice_id: number): Observable<KinoBookingInvoiceResponse>
  {
    return this.http.get<KinoBookingInvoiceResponse>(this.api.invoice + `/${invoice_id}/details`);
  }

}

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FleaMarketService } from '../../../services/flea-market.service';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { QuickFleaMarketBookingModel } from '../../../models/flea-market.model';
import { STATUS_PAID, STATUS_NOT_PAID } from '../../../constants/statuses';
import { Price } from '../../../constants/price';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../../services/helper.service';
import { FleaInvoicePrintComponent } from '../../flea-invoice-print/flea-invoice-print.component';

@Component({
  selector: 'app-quick-booking-dialog',
  templateUrl: './quick-booking-dialog.component.html',
  styleUrls: ['./quick-booking-dialog.component.scss']
})
export class QuickBookingDialogComponent implements OnInit, OnDestroy
{
  form: FormGroup;

  processing = false;

  get paid_amount_control() { return this.form.controls['paid'] as FormControl; }
  get total_control() { return this.form.controls['total'] as FormControl; }
  get discount_control() { return this.form.controls['discount'] as FormControl; }

  set total(value: number) { this.total_control.setValue(value); }
  get total(): number { return this.total_control.value; }

  constructor(
    public dialogRef: MatDialogRef<QuickBookingDialogComponent>,
    private fleaMarket: FleaMarketService,
    @Inject(MAT_DIALOG_DATA) public data: { spots: string[], date: string },
    private i18n: TranslateService,
    private helper: HelperService,
    private dialog: MatDialog,
    private fb: FormBuilder
  )
  { }

  ngOnInit()
  {
    this.form = this.fb.group({
      paid: [Price.FLEA * this.data.spots.length, Validators.compose([Validators.required, Validators.min(0)])],
      total: [Price.FLEA * this.data.spots.length, Validators.compose([Validators.required, Validators.min(0)])],
      discount: [false, Validators.required]
    });

    this.calcTotal();

    console.log('DATA: ', this.data);

    this.discount_control.valueChanges.subscribe(value =>
    {
      this.calcTotal();
      this.checkIfPricePayedIsGreaterThanTotal();
    });

    this.paid_amount_control.valueChanges.subscribe(value => this.checkIfPricePayedIsGreaterThanTotal());

    this.total_control.valueChanges.subscribe(value => {
      this.paid_amount_control.setValue(value);
    });
  }

  ngOnDestroy(): void
  {
  }

  /**
   * Calculates the total price of the booking
   */
  private calcTotal()
  {
    if (this.discount_control.value)
    {
      this.total = this.data.spots.length * Price.FLEA_DISCOUNT;
    }
    else
    {
      this.total = this.data.spots.length * Price.FLEA;
    }
  }

  private checkIfPricePayedIsGreaterThanTotal()
  {
    if (this.paid_amount_control.value > this.total)
    {
      this.paid_amount_control.setValue(this.total, { emitEvent: false });
    }
  }

  getTicketPrice(): number
  {
    return this.helper.getTicketPriceBasedOnTotal(this.total, this.data.spots.length, 1);
  }

  saveBooking()
  {
    if (!this.form.valid)
    {
      return;
    }

    const booking: QuickFleaMarketBookingModel = {
      price: this.getTicketPrice(),
      discount: this.discount_control.value,
      booking_dates: [{ date: this.data.date }],
      spots: this.data.spots.map(s => ({ name: s })),
      status: STATUS_NOT_PAID
    };

    console.log('Booking', booking);

    this.processing = true;

    this.fleaMarket.createFleaMarketQuickBooking(booking).subscribe(book_res =>
    {
      console.log('Booking response: ', book_res);

      if (this.paid_amount_control.value > 0)
      {
        this.fleaMarket.createFleaMarketInvoice(book_res.id, this.paid_amount_control.value)
          .subscribe(inv_res =>
          {
            console.log('Invoice response', inv_res);

            this.fleaMarket.getFleaInvoice(inv_res.id).subscribe(res =>
            {
              const printDialog = this.dialog.open(FleaInvoicePrintComponent, { data: res, disableClose: true, autoFocus: false });
              printDialog.afterClosed().subscribe(dialogResponse =>
              {
                this.helper.successNoti(this.i18n.instant('NOTI.booking_saved'));
                this.dialogRef.close({ ok: true });
              });
            });

          }, err =>
            {
              console.log('Error with quick booking invoice: ', err);
              this.processing = false;
              this.helper.errorNoti(this.i18n.instant('NOTI.error_saving_booking'), err.error.message);
            });
      }
      else
      {
        this.helper.successNoti(this.i18n.instant('NOTI.booking_saved'));
        this.dialogRef.close({ ok: true });
      }
    }, err =>
      {
        console.log('Error with quick booking: ', err);
        this.processing = false;
        this.helper.errorNoti(this.i18n.instant('NOTI.error_saving_booking'), err.error.message);
      });
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Moment } from 'moment';
import * as moment from 'moment';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CarMarketService } from '../../services/car-market.service';
import { CarMarketBookingModel, CarMarketBookingModelResponse } from '../../models/car-market.model';
import { STATUS_NOT_PAID, STATUS_PAID, STATUS_STORNO } from '../../constants/statuses';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../services/loading.service';
import { Price } from '../../constants/price';
import { MatDialog } from '../../../../node_modules/@angular/material';
import { CarInvoicePrintComponent } from '../car-invoice-print/car-invoice-print.component';
import { ChangesNotSavedDialogComponent } from '../changes-not-saved-dialog/changes-not-saved-dialog.component';

@Component({
  selector: 'app-car-market-booking',
  templateUrl: './car-market-booking.component.html',
  styleUrls: ['./car-market-booking.component.scss']
})
export class CarMarketBookingComponent implements OnInit, OnDestroy
{
  loadingData = false;

  dates: string[] = [];
  addAmountDropdown: number[] = [1, 2, 3, 4, 5, 10];

  datesForm: FormGroup;
  ticketPriceForm: FormGroup;
  userInfoForm: FormGroup;
  carInfoForm: FormGroup;

  selectedFile: File;
  image: string;
  imageUploading = false;
  bookingID;

  booking: CarMarketBookingModelResponse;

  topayControl: FormControl;

  get unitPrice() { return this.ticketPriceForm.controls.unit_price as FormControl; }
  get sum() { return this.ticketPriceForm.controls.sum as FormControl; }

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private carService: CarMarketService,
    private router: Router,
    private helper: HelperService,
    private i18n: TranslateService,
    private ls: LoadingService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  )
  { }

  ngOnInit()
  {
    this.ls.loading = true;
    this.loadingData = true;
    const yearAgo = moment().locale('de').endOf('week').subtract(52, 'week');

    for (let index = 0; index <= 156; index++) // Iterate over dates to get the 3 year range
    {
      this.dates.push(yearAgo.clone().add(index, 'week').format('dddd, DD.MM.YYYY')); // Push each sunday date to the array of dates
    }

    this.bookingID = this.route.snapshot.params.id;

    if (this.bookingID) // If booking id exist, that means that we are editing an existing booking
    {
      this.carService.getBookingByID(this.bookingID).subscribe(res =>
      {
        this.ls.loading = false;
        this.loadingData = false;
        console.log('Booking details', res);
        this.booking = res;
        this.createForm(res);

      }, err =>
        {
          console.log(err);
          this.ls.loading = false;
          this.loadingData = false;
          this.helper.errorNoti(this.i18n.instant('NOTI.error_getting_booking'));
          this.location.back();
        });
    }
    else // Else a new booking is being made
    {
      this.createForm();
      this.loadingData = false;
      this.ls.loading = false;
    }
  }
  ngOnDestroy(): void
  {
    this.helper.clearNotifications();
  }

  private createForm(booking?: CarMarketBookingModelResponse)
  {
    if (booking) // If the booking is being edited
    {
      // Initialize date form
      this.datesForm = this.fb.group({
        dates: this.fb.array(this.createDatesFormGroupArray(booking.booking_dates))
      });

      // Initialize ticket price form
      this.ticketPriceForm = this.fb.group({
        price_type: [{ value: booking.discount ? true : false, disabled: true }, Validators.required],
        unit_price: [{ value: booking.price, disabled: true }, Validators.compose([Validators.required, Validators.min(0)])],
        sum: [{ value: booking.total, disabled: true }]
      });

      // Initialize user form
      this.userInfoForm = this.fb.group({
        first_name: [booking.customer.first_name, Validators.required],
        last_name: [booking.customer.last_name, Validators.required],
        email: [booking.customer.email, Validators.compose([Validators.email, Validators.required])],
        phone_number: [booking.customer.phone],
        company: [booking.customer.company],
        street: [booking.customer.streetname],
        street_number: [booking.customer.street_number],
        place: [booking.customer.city],
        post_code: [booking.customer.post_code]
      });

      // Initialize car info form
      this.carInfoForm = this.fb.group({
        vehicle_manufacturers: [booking.car_details.car_make, Validators.required],
        vehicle_model: [booking.car_details.car_model, Validators.required],
        vehicle_price: [booking.car_details.car_price, Validators.required],
        ad_desc: [booking.car_details.description],
        vehicle_url: [booking.car_details.photo_url]
      });

      this.topayControl = new FormControl((booking.total - booking.payed_amount).toFixed(2), Validators.compose([
        Validators.required,
        Validators.min(0.01)
      ]));
    }
    else // If a new booking is being made
    {
      // Initialize date form
      this.datesForm = this.fb.group({
        dates: this.fb.array([
          new FormGroup({
            date: new FormControl(moment().locale('de').endOf('week').format('dddd, DD.MM.YYYY'), Validators.required)
          })
        ])
      });

      // Initialize ticket price form
      this.ticketPriceForm = this.fb.group({
        price_type: [false, Validators.required],
        unit_price: [Price.CAR, Validators.compose([Validators.required, Validators.min(0)])],
        sum: [Price.CAR, Validators.compose([Validators.required, Validators.min(0)])]
      });

      // Initialize user form
      this.userInfoForm = this.fb.group({
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        email: ['', Validators.compose([Validators.email, Validators.required])],
        phone_number: [''],
        company: [''],
        street: [''],
        street_number: [''],
        place: [''],
        post_code: ['']
      });

      // Initialize car info form
      this.carInfoForm = this.fb.group({
        vehicle_manufacturers: ['', Validators.required],
        vehicle_model: ['', Validators.required],
        vehicle_price: ['', Validators.required],
        ad_desc: [''],
        vehicle_url: ['']
      });
    }

    this.ticketPriceForm.controls['price_type'].valueChanges.subscribe(value =>
    {
      this.calcSum();
    });
    this.unitPrice.valueChanges.subscribe(v =>
    {
      if (v < 0)
      {
        this.unitPrice.setValue(0, { emitEvent: false });
      }
      this.calculateSum();
    });
    this.sum.valueChanges.subscribe(v =>
    {
      if (v < 0)
      {
        this.sum.setValue(0, { emitEvent: false });
      }
      this.calculateTicketPrice();
    });

    this.carInfoForm.controls.vehicle_price.valueChanges.subscribe(v =>
    {
      if (v < 0)
      {
        this.carInfoForm.controls.vehicle_price.setValue(0, { emitEvent: false });
      }
    });
  }

  private calculateSum()
  {
    this.sum.setValue(
      this.unitPrice.value * this.getDatesControls().length,
      { emitEvent: false }
    );
  }

  private calculateTicketPrice()
  {
    this.unitPrice.setValue(
      this.helper.getTicketPriceBasedOnTotal(this.sum.value, 1, this.getDatesControls().length),
      { emitEvent: false }
    );
  }

  /**
   * Returns a form group array with formatted dates
   * @param dates
   */
  private createDatesFormGroupArray(dates: { id: number, date: string }[]): FormGroup[]
  {
    const formGroupArray: FormGroup[] = [];

    dates.forEach((element, i) =>
    {
      formGroupArray.push(
        new FormGroup({
          date: new FormControl(
            { value: moment(element.date, 'YYYY-MM-DD').locale('de').format('dddd, DD.MM.YYYY'), disabled: true },
            Validators.required)
        })
      );
    });
    return formGroupArray;
  }

  /**
   * Gets the dates form groups
   */
  getDatesControls(): FormGroup[]
  {
    // @ts-ignore
    return (<FormGroup>this.datesForm.get('dates')).controls;
  }
  /**
   * Gets the field's value
   * @param field
   */
  getUserFormControlValue(field: string)
  {
    return this.userInfoForm.controls[field].value;
  }
  /**
   * Gets the field's value
   * @param field
   */
  getCarFormControlValue(field: string)
  {
    return this.carInfoForm.controls[field].value;
  }

  goBack()
  {
    if (this.datesForm.touched || this.ticketPriceForm.touched || this.userInfoForm.touched || this.carInfoForm)
    {
      const dialogRef = this.dialog.open(ChangesNotSavedDialogComponent, { autoFocus: false, minWidth: 300 });
      dialogRef.afterClosed().subscribe(dialog_response =>
      {
        console.log(dialog_response);
        if (dialog_response)
        {
          this.location.back();
        }
      });
    }
    else
    {
      this.location.back();
    }
  }

  private calcSum()
  {
    this.ticketPriceForm.controls.unit_price.setValue(
      this.ticketPriceForm.controls.price_type.value ? Price.CAR_DISCOUNT : Price.CAR
    );

    this.ticketPriceForm.controls['sum'].setValue(
      this.ticketPriceForm.controls.price_type.value ?
        Price.CAR_DISCOUNT * this.getDatesControls().length :
        Price.CAR * this.getDatesControls().length
    );
  }

  /**
   * Adds new date control to the formArray
   * @param amount The amount of dates to add
   */
  addNewDate(amount: number = 1)
  {
    // Iterate the amount of time specified
    for (let i = 0; i < amount; i++)
    {
      // Get the index of the last form group control in form array
      const len = this.getDatesControls().length;

      // Get the date value of the date control
      const last_date_value = this.getDatesControls()[len - 1].controls['date'].value;

      // Add a week to the found control
      const value = this.getMomentFromString(last_date_value).add(1, 'week');

      (<FormArray>this.datesForm.get('dates')).push(new FormGroup({
        // Initialize the new form control with a date one week in advance
        date: new FormControl(this.getStringDateFromMoment(value), Validators.required)
      }));
    }

    this.calculateSum();
  }

  /**
   * Removes the date control from the formArray on the provided index
   * @param num Index of the form control
   */
  removeDate(num: number)
  {
    (<FormArray>this.datesForm.get('dates')).removeAt(num);
    this.calculateSum();
  }

  onFileChanged(files: File[])
  {
    this.selectedFile = files[0]; // Set the selected file

    // Make the file into a base64 string to show it in the preview
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = () =>
    {
      this.image = <string>reader.result;

      this.imageUploading = true;

      // Upload the file to the back end
      this.carService.uploadImage(this.image).subscribe(res =>
      {
        console.log('Upload response', res);
        this.carInfoForm.controls.vehicle_url.setValue(res.url);
        this.imageUploading = false;

      }, err => console.log('Error on upload', err));
    };
    reader.onerror = function (error)
    {
      console.log('Error: ', error);
    };

  }

  submitForm()
  {
    const data: CarMarketBookingModel = {
      status: STATUS_NOT_PAID,
      price: this.unitPrice.value,
      discount: this.ticketPriceForm.controls.price_type.value,
      booking_dates: this.getBookingDates(),
      customer: {
        first_name: this.getUserFormControlValue('first_name'),
        last_name: this.getUserFormControlValue('last_name'),
        email: this.getUserFormControlValue('email'),
        phone: this.getUserFormControlValue('phone_number'),
        company: this.getUserFormControlValue('company'),
        streetname: this.getUserFormControlValue('street'),
        street_number: this.getUserFormControlValue('street_number'),
        city: this.getUserFormControlValue('place'),
        post_code: this.getUserFormControlValue('post_code')
      },
      car_details: {
        car_price: this.getCarFormControlValue('vehicle_price'),
        car_make: this.getCarFormControlValue('vehicle_manufacturers'),
        car_model: this.getCarFormControlValue('vehicle_model'),
        description: this.getCarFormControlValue('ad_desc'),
        photo_url: this.getCarFormControlValue('vehicle_url')
      }
    };

    console.log('');
    console.log('DATA', data);
    console.log('');

    if (this.bookingID)
    {
      if (!this.userInfoForm.valid || !this.carInfoForm.valid)
      {
        this.helper.warningNoti(this.i18n.instant('NOTI.fill_required_fields'));
        this.markControlsAsTouch();
        return;
      }
      this.ls.loading = true;

      data.id = this.bookingID;
      this.carService.updateCarMarketBooking(data).subscribe(res =>
      {
        this.ls.loading = false;
        console.log('Car booking updated response:', res);
        this.helper.successNoti(this.i18n.instant('NOTI.booking_saved'));
        this.router.navigate(['/car-market']);

      }, (err: HttpErrorResponse) =>
        {
          this.ls.loading = false;
          this.helper.errorNoti(this.i18n.instant('NOTI.error_saving_booking', err.error.message));
          console.log('Car market booking update error', err);
        });

    }
    else
    {
      if (!this.areFormsValid())
      {
        this.helper.warningNoti(this.i18n.instant('NOTI.fill_required_fields'));
        console.log('Valid ', this.areFormsValid());
        this.markControlsAsTouch();
        return;
      }
      this.ls.loading = true;

      this.carService.createCarMarketBooking(data).subscribe(res =>
      {
        this.ls.loading = false;
        console.log('Car booking response:', res);
        this.helper.successNoti(this.i18n.instant('NOTI.booking_saved'));
        this.router.navigate(['/car-market']);

      }, (err: HttpErrorResponse) =>
        {
          this.ls.loading = false;
          this.helper.errorNoti(this.i18n.instant('NOTI.error_saving_booking', err.error.message));
          console.log(err);
        });
    }
  }

  areFormsValid(): boolean
  {
    return this.datesForm.valid && this.userInfoForm.valid && this.carInfoForm.valid && this.ticketPriceForm.valid;
  }

  private markControlsAsTouch()
  {
    Object.keys(this.userInfoForm.controls).forEach(field =>
    {
      const control = this.userInfoForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    Object.keys(this.carInfoForm.controls).forEach(field =>
    {
      const control = this.carInfoForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    Object.keys(this.ticketPriceForm.controls).forEach(field =>
    {
      const control = this.ticketPriceForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  /**
   * Get string array of booking dates to send to backend
   */
  private getBookingDates(): { date: string }[]
  {
    const booking_dates = [];

    this.getDatesControls().forEach(date =>
    {
      const mom_date = this.getMomentFromString(date.controls.date.value);

      booking_dates.push({ date: mom_date.format('YYYY-MM-DD') });
    });

    return booking_dates;
  }

  /**
   * Converts the date in string format to a moment object
   * @param date
   * @param format
   */
  private getMomentFromString(date: string, format = 'dddd, DD.MM.YYYY'): Moment
  {
    return moment(date, format, 'de', true);
  }

  /**
   * Converts the moment object to a string format
   * @param date
   * @param format
   */
  private getStringDateFromMoment(date: Moment, format = 'dddd, DD.MM.YYYY'): string
  {
    return moment(date).locale('de').format(format);
  }


  addUntilEndOfMonth()
  {
    // Get current value
    const date = this.getDatesControls()[this.getDatesControls().length - 1].controls['date'].value;
    // Get current date's end of month
    const date_a = this.getMomentFromString(date).endOf('month');
    // Get current date to moment object
    const date_b = this.getMomentFromString(date);
    // Get the difference in weeks to the end of the month
    const diff = date_a.diff(date_b, 'week');
    // Add the amount of dates in difference
    this.addNewDate(diff);
  }
  addUntilEndOfYear()
  {
    // Get current value
    const date = this.getDatesControls()[this.getDatesControls().length - 1].controls['date'].value;
    // Get current date's end of month
    const date_a = this.getMomentFromString(date).endOf('year');
    // Get current date to moment object
    const date_b = this.getMomentFromString(date);
    // Get the difference in weeks to the end of the month
    const diff = date_a.diff(date_b, 'week');
    // Add the amount of dates in difference
    this.addNewDate(diff);
  }
  addOneYear()
  {
    this.addNewDate(52); // Add 52 weeks
  }

  /**
   * Creates an invoice
   */
  createInvoice()
  {
    if (this.booking && (this.booking.status === STATUS_PAID || this.booking.status === STATUS_STORNO))
    {
      this.carService.getCarInvoice(this.booking.invoices[0].id).subscribe(res =>
      {
        this.dialog.open(CarInvoicePrintComponent, { data: res, disableClose: true, autoFocus: false });
      });
    }
    else
    {
      this.ls.loading = true;
      this.carService.createCarMarketInvoice(this.bookingID, this.topayControl.value).subscribe(res =>
      {
        this.ls.loading = false;

        const dialogRef = this.dialog.open(CarInvoicePrintComponent, { data: res, disableClose: true, autoFocus: false });
        dialogRef.afterClosed().subscribe(dialogResponse =>
        {
          this.router.navigate(['/car-market']);
        });
      }, err =>
      {
        console.log('Error creating invoice', err);
        this.helper.errorNoti(this.i18n.instant('ERR.error_create_invoice'));
        this.ls.loading = false;
      });
    }
  }

  createStorno()
  {
    this.ls.loading = true;
    this.carService.stornoCarMarketBooking(this.bookingID).subscribe(res =>
    {
      console.log('Storno response: ', res);
      this.ls.loading = false;

      const dialogRef = this.dialog.open(CarInvoicePrintComponent, { data: res, disableClose: true, autoFocus: false });
      dialogRef.afterClosed().subscribe(dialogResponse =>
      {
        this.router.navigate(['/car-market']);
      });

    }, err =>
      {
        console.log('Storno error', err);
        this.ls.loading = false;
      });
  }

  /**
   * Calculate how many weeks are left in a booking
   */
  remainingWeeks(): number
  {
    let rem = 0;

    if (this.booking)
    {
      this.booking.booking_dates.forEach(element =>
      {
        if (moment(element.date, 'YYYY-MM-DD').isAfter(moment()))
        {
          rem++;
        }
      });
    }

    return rem || 0;
  }

  /**
   * Prints the invoice
   * @param invoice_id
   */
  printInvoiceById(invoice_id: number)
  {
    this.carService.getCarInvoice(invoice_id).subscribe(res =>
    {
      const dialogRef = this.dialog.open(CarInvoicePrintComponent, { data: res, disableClose: true, autoFocus: false });
    });
  }
}
